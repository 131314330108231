import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../../api/axios";

function PaySuccess() {
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("__firebase_request_key")

    useEffect(() => {
      const invokePaymentVerifier = async () => {
        const token = searchParams.get('token');
        const verifier = searchParams.get('success');

        axios.put(
            '/pay/verify',
            {
                token,
                verifier
            }
        )
      }

      invokePaymentVerifier();
    }, [searchParams])
    
    return (
        <section className="GLOBAL-A001B px-4 py-3 d-flex flex-column align-items-start">
            <span className="display-5 my-2">Your payment was successful! Thank you.</span>
            <span className="a001b-span my-2">You will receive email confirmation soon.</span>
            <button className="a001b-button tp-button">
                <a href="/" className="no-href-effect">Home page</a>
            </button>
        </section>
    )
}

export default PaySuccess;