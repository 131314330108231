import 'bootstrap/dist/css/bootstrap.css';
import './Cookies.css';

function Cookies() {
    return(
        <section className="G-1 px-2">
            <h1 className='py-3 display-2'>Cookie Policy</h1>
            <span className='last-updated'>This document was last updated on December 14, 2022 (v3.14/12.22*2145)</span>

            <div className='d-flex flex-column mt-5 mb-3 mx-2 p-2 index-table'>
                <span>Sections of this document</span>
                <a href='#ccpsec1'>How we use cookies</a>
                <a href='#ccpsec2'>First-party cookies</a>
                <a href='#ccpsec3'>Third-party cookies</a>
                <a href='#ccpsec4'>What are your choices regarding cookies?</a>
                <a href='#ccpsec5'>Google Analytics Cookies</a>
            </div>

            <p id="ccpsec1" className='display-6 pt-4'><i><b>How we use cookies</b></i></p>
            <p>We use cookies and similar technologies for several purposes, depending on the context or service, including: </p>
            <ul>
                <li>Recognizing you if you sign in to use our offerings. This allows us to provide you with recommendations, display personalized content, and provide other customized features and services.</li>
                <li>Keeping track of your specified preferences. This allows us to honor your likes and dislikes, such as your language and configuration preferences.</li>
                <li>Detecting and preventing fraudulent activity and improving security.</li>
                <li>Conducting research and diagnostics to improve our offerings.</li>
                <li>Reporting, which allows us to measure and analyze the performance of our offerings.</li>
            </ul>


            <p id="ccpsec2" className='display-6 pt-4'><i><b>First-party cookies</b></i></p>
            <ol type='a'>
                <li>
                <b>Strictly Necessary cookies</b>: These cookies are necessary for the website to function and cannot be switched off in our systems. They are often set in response to actions made by you, which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms.
                </li>
                <li>
                <b>Functional cookies</b>: These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages.
                </li>
                <li>
                <b>Performance cookies</b>: These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.
                </li>
                <li>
                <b>Targeting cookies</b>: These cookies may be set through our site and may be used to build a profile of your interests and show you relevant adverts on other sites.
                </li>
            </ol>


            <p id="ccpsec3" className='display-6 pt-4'><i><b>Third-party cookies</b></i></p>
            <p>In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on. These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites.</p>


            <p id="ccpsec4" className='display-6 pt-4'><i><b>What are your choices regarding cookies?</b></i></p>
            <p><b>Cookie Preferences on AllCityCleaning website</b>: Our cookies allow you to take advantage of some essential and useful features. Blocking some types of cookies may impact your experience of our sites. You can change your cookie preferences at any time by clicking 'Cookie Preferences' in the footer of the AllCityCleaning website.</p>
            <p><b>Browser settings</b>: You can also manage browser cookies through your browser settings. The 'Help' feature on most browsers will tell you how to remove cookies from your device, prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, how to disable cookies, and when cookies will expire. Check the support site for your browser to understand privacy settings available to you. If you block or reject some of our cookies through your browser's settings, you might not be able to use certain offerings that require you to sign-in to an account and some features and services may not work. You might also have to manually adjust your preferences or settings every time you visit our website.</p>


            <p id="ccpsec5" className='display-6 pt-4'><i><b>Google Analytics Cookies</b></i></p>
            <p>We use Google Analytics to investigate the utilization of our site of users and visitors. Google Analytics assembles data about site use through cookies. The data assembled identifying with our site is utilized to make reports about the utilization of our site.</p>
            <div className='cpp-table-parent'> 
                <table className='ccp-table'>
                <tbody>
                    <tr>
                        <th><b>Name of cookie</b></th>
                        <th><b>Origin/Domain</b></th>
                        <th><b>Purpose</b></th>
                        <th><b>Duration</b></th>
                        <th><b>Type</b></th>
                    </tr>
                    <tr>
                        <td>_ga</td>
                        <td>.allcitycleaning.co.uk</td>
                        <td>The _ga cookie, installed by Google Analytics, calculates visitor, session and campaign data and also keeps track of site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognize unique visitors.</td>
                        <td>2 years</td>
                        <td>Analytics</td>
                    </tr>
                    <tr>
                        <td>_gid</td>
                        <td>.allcitycleaning.co.uk</td>
                        <td>Installed by Google Analytics, _gid cookie stores information on how visitors use a website, while also creating an analytics report of the website's performance. Some of the data that are collected include the number of visitors, their source, and the pages they visit anonymously.</td>
                        <td>1 day</td>
                        <td>Analytics</td>
                    </tr>
                    <tr>
                        <td>_gat_gtag_UA_237478215_1</td>
                        <td>.allcitycleaning.co.uk</td>
                        <td>Set by Google to distinguish users.</td>
                        <td>1 minute</td>
                        <td>Analytics</td>
                    </tr>
                    <tr>
                        <td>_ga_C5MXR4S426</td>
                        <td>.allcitycleaning.co.uk</td>
                        <td>This cookie is installed by Google Analytics.</td>
                        <td>2 years</td>
                        <td>Analytics</td>
                    </tr>
                    <tr>
                        <td>_fbp</td>
                        <td>.allcitycleaning.co.uk</td>
                        <td>This cookie is set by Facebook to display advertisements when either on Facebook or on a digital platform powered by Facebook advertising, after visiting the website.</td>
                        <td>3 months</td>
                        <td>Advertisement</td>
                    </tr>
                </tbody>
                </table>
            </div>
        </section>
    )
}

export default Cookies;