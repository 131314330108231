import './Statistics.css'

const statsBlock = (title: string, subText: string) => {
    return (
        <div className='COLOR-RE12 d-flex flex-column align-items-center'>
            <span className='stats-title'>{title}</span>
            <span className='stats-subtext'>{subText}</span>
        </div>
    )
}

function Statistics() {
    return (
        <section id='stats-line' className='COLOR-RR0 d-flex flex-row justify-content-around py-4'>
            {statsBlock('1000+','Happy Customer')}
            {statsBlock('10+', 'Years Of Experience')}
            {statsBlock('28', 'Serviced Postcodes')}
            {statsBlock('24/7', 'Availability')}
        </section>
    )
}

export default Statistics;