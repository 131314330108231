import 'bootstrap/dist/css/bootstrap.css';

function UserAgreement() {
    return(
        <section className="G-1 px-2">
            <h1 className='py-3 display-2'>User Agreement</h1>
            <span className='last-updated'>This document was last updated on November 22, 2022</span>

            <div className='d-flex flex-column mt-5 mb-3 mx-2 p-2 index-table'>
                <span>Sections of this document</span>
                <a href='#uasct1'>Agreement to Terms</a>
                <a href='#uasct2'>Contact Information</a>
                <a href='#uasct3'>Use of Information and Services</a>
                <a href='#uasct4'>Advice</a>
                <a href='#uasct5'>No Liability</a>
                <a href='#uasct6'>Site Conduct</a>
                <a href='#uasct7'>Removal of Content</a>
                <a href='#uasct8'>Submission of Content</a>
                <a href='#uasct9'>Internet Links</a>
                <a href='#uasct10'>Third Parties</a>
                <a href='#uasct11'>Indemnification</a>
                <a href='#uasct12'>Modifications to Site</a>
                <a href='#uasct13'>Use and Storage of Content on the Site</a>
                <a href='#uasct14'>Ownership of Content</a>
                <a href='#uasct15'>Trademarks</a>
                <a href='#uasct16'>Disclaimer of Warranty</a>
                <a href='#uasct17'>Limitation of Liability</a>
                <a href='#uasct18'>Termination</a>
                <a href='#uasct19'>Notices</a>
                <a href='#uasct20'>General Provisions</a>
                <a href='#uasct21'>Arbitration</a>
                <a href='#uasct22'>Questions or Comments</a>
            </div>


            <p id="uasct1" className='display-6 pt-4'><i><b>Agreement to Terms</b></i></p>
            <p>The AllCityCleaning provides access and use of this site ("Site") subject to your agreement to the following "Terms of Use" (the "Terms"). Please read these Terms carefully before accessing or using the Site. By accessing or using the Site, you agree to be bound by these Terms.</p>
            <p>The Terms, as well as the Site (and information contained within the Site) may be updated or revised from time to time without notice to you. You agree to periodically review these Terms, and your continued access to or use of the Site shall constitute your acceptance of the updated or revised Terms.</p>


            <p id="uasct2" className='display-6 pt-4'><i><b>Contact Information</b></i></p>
            <p>If asked to provide your email address during your use of the site, please provide an accurate and complete email address.</p>


            <p id="uasct3" className='display-6 pt-4'><i><b>Use of Information and Services</b></i></p>
            <p>Third parties may provide certain information displayed on the Site. Although the AllCityCleaning believes that such information comes from trustworthy sources, the third-party information is not necessarily sponsored, endorsed, recommended, or licensed by the AllCityCleaning. You should directly contact the third parties and/or their references for the most accurate information.</p>


            <p id="uasct4" className='display-6 pt-4'><i><b>Advice</b></i></p>
            <p>Neither the AllCityCleaning nor its information sources or users are engaged in giving legal, medical, counseling, or other professional services or advice in this Site.</p>


            <p id="uasct5" className='display-6 pt-4'><i><b>No Liability</b></i></p>
            <p>Under no circumstances will AllCityCleaning be liable for any consequence relating directly or indirectly to any action or inaction you take based on the information, services, or other material on the Site. While the AllCityCleaning will attempt to keep its Site current, accurate, and complete, the AllCityCleaning and its suppliers cannot guarantee, and will not be responsible for any damage or loss related to, the timeliness, accuracy, or completeness of the information, services, or other material on the Site.</p>


            <p id="uasct6" className='display-6 pt-4'><i><b>Site Conduct</b></i></p>
            <p>When using the Site, you agree to abide by the following rules: (a) you may not post or transmit content (e.g., information, data, text, software, graphics, sound, photographs, video, messages, etc.) ("Content") that is unlawful, harassing, libelous, defamatory, obscene, fraudulent, harmful, threatening, abusive, hateful, that violates the property rights of others (including without limitation infringing use of a copyright or trademark), that violates the privacy or publicity right of others, or are otherwise objectionable; (b) you may not interfere with other users' use of the Site; (c) you may not use the Site to conduct any activity that is illegal or that violates the rights of others; (d) you may not use the Site to advertise or sell products or services to others; (e) you may not impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity; (f) you may not post or transmit Content that you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, confidential information, etc.); (g) you may not post Content to the Site unless the Content is freely owned by you or used with the permission of all other persons with any rights in the Content; (h) because the AllCityCleaning is a nonprofit organization, you may not use the site for any organized political activity; and (i) you may not use the Site to collect or store personal data about other users.</p>


            <p id="uasct7" className='display-6 pt-4'><i><b>Removal of Content</b></i></p>
            <p>The AllCityCleaning does not do a prior review of Content posted by any party other than the AllCityCleaning and has no responsibility for Content posted by any party other than the AllCityCleaning to the Site. However, the AllCityCleaning reserves the right in its sole discretion to delete any Content submitted to or appearing on the Site. Message boards, "chat rooms" and/or other information sharing locations on the Site may contain the opinions and views of other users. AllCityCleaning is not responsible for the accuracy of any messages or postings on the Site. If you believe that any Content posted on the Site is infringing, defamatory or otherwise violate the letter or spirit of these Terms, please contact the AllCityCleaning immediately.</p>


            <p id="uasct8" className='display-6 pt-4'><i><b>Submission of Content</b></i></p>
            <p>If you submit Content to the Site, you grant to the AllCityCleaning, or warrant that the owner of such Content has expressly granted to the AllCityCleaning, a world-wide, royalty-free, perpetual, irrevocable, transferable, non-exclusive and fully sub-licensable right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such Content (in whole or part) and/or to incorporate such Content in other works in any form, media, or technology now known or later developed.</p>


            <p id="uasct9" className='display-6 pt-4'><i><b>Internet Links</b></i></p>
            <p>The Site may provide, or third parties may provide, links to other Internet sites. The AllCityCleaning has no control over such sites; the AllCityCleaning does not endorse, and is not responsible for, any such sites or the information, material, or services contained on or accessible through those sites. You acknowledge that the AllCityCleaning shall not be responsible or liable, directly, or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance upon any such information, material, products, or services.</p>   


            <p id="uasct10" className='display-6 pt-4'><i><b>Third Parties</b></i></p>
            <p>Your correspondence or business transactions with, or participation in promotions of, third parties found on, or e-commerce through, the Site and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such third party. You agree that the AllCityCleaning shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such transactions or as the result of the presence of such third parties on the Site.</p>


            <p id="uasct11" className='display-6 pt-4'><i><b>Indemnification</b></i></p>
            <p>You agree to indemnify, defend, and hold harmless the AllCityCleaning, its affiliates, governing board, officers, agents, employees, partners, and suppliers from any liability, loss, claim, demand, and expense (including reasonable attorneys' fees) related to (a) your use of the Site; (b) your violation of these Terms; or (c) your posting of Content to the Site.</p>


            <p id="uasct12" className='display-6 pt-4'><i><b>Modifications to Site</b></i></p>
            <p>The AllCityCleaning reserves the right to, from time to time, discontinue, temporarily or permanently, the information, services, products, and/or other Content on the Site with or without notice.</p>


            <p id="uasct13" className='display-6 pt-4'><i><b>Use and Storage of Content on the Site</b></i></p>
            <p>The AllCityCleaning may establish general practices and limits concerning the use of the Site, including without limitation, the maximum number of days that Content will be available on, or retained by, the Site. The AllCityCleaning, in its sole discretion, reserves the right to change these general practices and limits at any time.</p>


            <p id="uasct14" className='display-6 pt-4'><i><b>Ownership of Content</b></i></p>
            <p>All information on the Site, as well as the organization and layout of the site, other than Content posted by you, is owned, and copyrighted by the AllCityCleaning or its suppliers or other Site users. Except as expressly authorized by the AllCityCleaning, you may not copy, distribute, resell, display, sublicense, or create derivative works based on any such information from the Site, in whole or in part.</p>


            <p id="uasct15" className='display-6 pt-4'><i><b>Trademarks</b></i></p>
            <p>The logo, name, and all graphics on the Site of the AllCityCleaning is property of the AllCityCleaning. Use, reproduction, copying, or redistribution of trademarks, without the written permission of the AllCityCleaning is prohibited. All other trademarks or service marks appearing on the Site are the marks of their respective owners.</p>


            <p id="uasct16" className='display-6 pt-4'><i><b>Disclaimer of Warranty</b></i></p>
            <p>You expressly understand and agree that this site, and all information and content on the site, is provided on an "as is" and "as available" basis. The AllCityCleaning and its information suppliers disclaim all express and implied warranties about the site and all information, services, and materials contained on the site, including without limitation any implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <p>The AllCityCleaning makes no warranty that: (a) the site will meet your requirements or that the results that may be obtained from the site will be satisfactory; (b) the site will be uninterrupted, timely, secure, or error-free; (c) the quality of any products, services, information, or other material purchased or obtained by you through the site will meet your expectations; and (d) any errors in the site will be corrected.</p>
            <p>No advice or information, whether oral or written, obtained by you from the AllCityCleaning or through the site shall create any warranty not expressly stated in the terms.</p>


            <p id="uasct17" className='display-6 pt-4'><i><b>Limitation of Liability</b></i></p>
            <p>In no event shall the AllCityCleaning or its affiliates, governing board, officers, agents, employees, partners, volunteers, or suppliers be liable for any special, indirect, punitive, incidental, exemplary or consequential damages, or any damages whatsoever resulting from loss of use, business, data or profits, litigation, and the like, whether based on breach of contract, tort, (including negligence), product liability or otherwise, even if advised of the possibility of such damages. With respect to goods or services purchased directly from the AllCityCleaning's liability, in any case, is expressly limited to replacement of defective goods, or, at the AllCityCleaning's election, to the repayment or crediting to you of an amount equal to the purchase price of the goods. You acknowledge and agree that the limitations set forth above are fundamental elements of these terms of use and the site would not be provided to you absent such limitations. If any jurisdictions do not allow the foregoing limitations of liability, the foregoing limitations may not apply to you.</p>


            <p id="uasct18" className='display-6 pt-4'><i><b>Termination</b></i></p>
            <p>The AllCityCleaning may, in its sole discretion, with or without notice, suspend or terminate your use of this Site, and remove and discard any Content on the Site, for any reason, including, without limitation if you violate these Terms. The AllCityCleaning may also bar you from any future access to the Site. The AllCityCleaning will not be liable to you for any such suspension, termination, or prohibition on future access.</p>
            <p>Should you object to any subsequent modifications of the terms and conditions of these Terms, your sole recourse will be to immediately notify the AllCityCleaning of your termination of these Terms, which termination will be effective immediately. To the extent permitted by applicable law, the terms and conditions of these Terms will survive any termination of these Terms.</p>


            <p id="uasct19" className='display-6 pt-4'><i><b>Notices</b></i></p>
            <p>Any notices to you by the AllCityCleaning may be made by either email or regular mail.</p>


            <p id="uasct20" className='display-6 pt-4'><i><b>General Provisions</b></i></p>
            <p>These Terms constitute the entire agreement between you and the AllCityCleaning and govern your use of the Site, superseding any prior agreements between you and the AllCityCleaning regarding the Site (including, but not limited to, any prior versions of these Terms).</p>
            <p>These Terms and the resolution of any dispute related to these Terms, or the Site shall be governed by and construed in accordance with the laws of United Kingdom, without giving effect to any principles of conflicts of law. The AllCityCleaning's failure to insist upon strict enforcement of any provision of these Terms shall not be construed as a waiver of any provision or right. If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will be enforced in accordance with their terms. The section headings in these Terms are for convenience only and have no legal or contractual effect.</p>
            <p>Regardless of any statute or law to the contrary, you hereby waive your right to bring any claim against the AllCityCleaning more than 1 year after such claim first arose.</p>


            <p id="uasct21" className='display-6 pt-4'><i><b>Arbitration</b></i></p>
            <p>Any controversy or claim arising out of or relating to these Terms will be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association. Any such controversy or claim will be arbitrated on an individual basis and will not be consolidated in any arbitration with any claim or controversy of any other party.</p>


            <p id="uasct22" className='display-6 pt-4'><i><b>Questions or Comments</b></i></p>
            <p>If you have any questions about these terms, the practices of this site, or your dealings with this Web site, or if you wish to report any violations of these Terms, you can contact <u>office@allcitycleaning.co.uk</u> or call us at <b>+44 (0) 333 090 14 64</b>.</p>
        </section>
    )
}

export default UserAgreement;