import React from 'react';
import './ThankPage.css';

function ThankPage() {
    return (
        <section className='py-5 px-4 COLOR-TP d-flex flex-column align-items-center'>
            <span className='display-4 py-3'>Thank you for your submission!</span>
            <span className='display-6 pb-5'>We'll get back to you within 30 minutes.</span>

            <div className='d-flex flex-column align-items-center'>
                <span className='mw-650 pb-4'>In the meantime, check our social media platforms and subscribe to our mailing list for exclusive 10% discount for your next booking with us, by going to the bottom of this page.</span>
                <video loop autoPlay muted width={'300px'} src='/video/v1.mp4' className='tp-video pb-4'>
                </video>
            </div>

            <div className='py-4'>
                <a className='no-href-effect' href='/'>
                    <button className='tp-button'>Home page</button>
                </a>
            </div>
        </section>
    )
}

export default ThankPage;