import React from 'react';
import './Hero.css';

const star = () => {
    return (
        <>
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" className='mx-1'>
                <path d="M10 0L12.645 6.35942L19.5106 6.90983L14.2798 11.3906L15.8779 18.0902L10 14.5L4.12215 18.0902L5.72025 11.3906L0.489435 6.90983L7.35497 6.35942L10 0Z" fill="#F1C644"/>
                <path d="M10 0L12.645 6.35942L19.5106 6.90983L14.2798 11.3906L15.8779 18.0902L10 14.5L4.12215 18.0902L5.72025 11.3906L0.489435 6.90983L7.35497 6.35942L10 0Z" stroke="#F1C644"/>
            </svg>
        </>
    )
}

function Hero() {
    return (
        <section id='hero-parent' className='COLOR-C0028 COLOR-C0020 d-flex flex-column align-items-start justify-content-center px-4'>
            <div id='hero-labels' className='d-flex flex-column mb-3'>
                <span className='display-4 w600 smw-font'>Carpet & Upholstery Cleaning</span>
                <span className='display-4 smw-font'>Available 24/7</span>
                <span className='display-4 smw-font'>At Unbeatable Rates</span>
                <span className='display-4 smw-font'>For Homes & Businesses</span>
            </div>
            <div id='hero-widgets' className='d-flex justify-content-start'>
                <div id='hero-quote-sec' className='COLOR-MB-23 my-3'>
                    <a href='/quote' className='no-href-effect'>
                        <button>Get My Quick Quote</button>
                    </a>
                    <img src='/img/pointer.png' alt='Pointing arrow'/>
                </div>
                <div id='hero-rating' className='d-flex flex-column align-items-center my-3'>
                    <div className='COLOR-MB-23 py-1 px-2 d-flex'>
                        <a href='https://www.trustpilot.com/review/allcitycleaning.co.uk'
                            target={'_blank'} rel='noreferrer' className='d-flex'> 
                        {star()}
                        {star()}
                        {star()}
                        {star()}
                        {star()}
                        </a>
                    </div>
                    <span className='hero-reviews-blink pt-1'>
                        <a href='https://www.trustpilot.com/review/allcitycleaning.co.uk'
                            className='no-href-effect' target={'_blank'} rel='noreferrer'>
                            4.7 Stars On TrustPilot
                        </a>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default Hero;