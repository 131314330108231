import './Gallery.css'

const panel = (heading: string, beforeRef: string, afterRef: string) => {
    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='py-5 text-center'>
                <span className='display-6'>{heading}</span>
            </div>
            <div className='gallery-parent COLOR-H1 my-3 mb-5'>
                <div>
                    <img src={`img/${beforeRef}`} alt='Before cleaning' />
                </div>
                <div>
                    <img src={`img/${afterRef}`} alt='After cleaning' />
                </div>
            </div>
        </div>
    )
}

function Gallery() {
    return (
        <section className="px-4 py-5">
            {panel('"I got my carpet cleaned and I must say I was amazed with how fast and friendly the staff were. The after results were also amazing"', 'gb_1.jpg', 'ga_1.jpg')}
            {panel('"Second time using your services and I am delighted. Cheaper and personalised service!"', 'gb_2.jpg', 'ga_2.jpg')}
        </section>
    )
}

export default Gallery;

