import 'bootstrap/dist/css/bootstrap.css';

function Disclaimer() {
    return(
        <section className="G-1 px-2">
            <h1 className='py-3 display-2'>Disclaimer</h1>

            <p>It is our objective that all texts and images provided, read, and seen on this website are solely for information, training, educational purpose only.  We do not desire to offend sense and mental sensibilities or our clients or customers.  We hope that everything that you find in this page is helpful, informative, and interesting for you.</p>
            <p>As a reminder, your use and share of information anywhere on this page are voluntarily given and acted by and from you.  We also have to take responsibility for your data and details and ensure they are protected, secured, and safe always.  Thus, we see to it that we shall continue to update all mechanisms and applications relevant to protecting your personal and professional information.</p>
            <p>As further protection between our cleaning company and you as our client, it is imperative that you should be aware that not everything can be protected. Thus, we reserve the right for freedom from any liabilities incurred due to malicious transactions not emanating from us such as unsecured served at your end, viruses and other unsecured threats from your side, and other issues not related and coming from us when it comes to security and privacy.</p>
            <p>As such, we guarantee that all information is exclusive to us.  Should there be instances where third parties are able to access your site details, personal profile, and other important details not revealed to us, we are totally absolved from any form of liabilities unless expressed by law and investigations.  We accept the fact that all data shared to us coming from you are correct, otherwise, we do not claim any form of liability should there be errors.</p>
            <p>Furthermore, we do not accept any type of liability with regard to information that you provided to us whether it is provided directly, indirectly, attached, implied, or involuntarily given.  By clicking on the accept button, we trust that you provided us all correct and relevant details.  Whatever, misinformation, errors, or omission that you conducted we are not liable or accessory to whatever problems legal or not that you encountered while conducting business with AllCityCleaning.</p>
        </section>
    )
}

export default Disclaimer;