import './ContactDetails.css';

function ContactDetails() {
    return(
        <section className='quick-actions-b-parent COLOR-L1 align-items-center py-2'>
            <div className='quick-actions-small-ex'>
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330.001 330.001" xmlSpace="preserve" className='quick-actions-b-svg'>
                    <g>
                        <path d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602 L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"/>
                        <polygon points="165.001,146.4 310.087,40.001 19.911,40 	"/>
                    </g>
                </svg>
                <span className='quick-actions-b-label'>
                    <a href='mailto:office@allcitycleaning.co.uk' className='href-no-effect COLOR-L2'>office@allcitycleaning.co.uk</a>
                </span>
            </div>
            <div>
                <button className='quick-actions-b-button'>
                    <a href='tel:03330901464' className='href-no-effect'>
                        <span className='quick-actions-b-m1 COLOR-L2'>Call Us</span>
                        <span className='quick-actions-b-m2 COLOR-L2'>0333 090 14 64</span>
                    </a>
                </button>
            </div>
        </section>
    )
}

export default ContactDetails;