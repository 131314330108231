import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import './components/Colors.css';
import './components/Global.css';
import NotFound from './components/not_found/NotFound';
import Login from './components/login/Login';
import Pay from './components/pay/Pay';
import PaySuccess from './components/pay/PaySuccess';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import TermsAndConditions from './components/terms-and-conditions/TermsAndConditions';
import Cookies from './components/cookies/Cookies';
import UserAgreement from './components/usage_agreement/UserAgreement';
import Disclaimer from './components/disclaimer/Disclaimer';
import Nav from './components/nav/Nav';
import Hero from './components/hero/Hero';
import ServicesSummary from './components/srv/ServicesSummary';
import Statistics from './components/stats_line/Statistics';
import Reviews from './components/reviews/Reviews';
import Gallery from './components/gallery/Gallery';
import ReadyToBook from './components/quick_actions/ReadyToBook';
import Footer from './components/footer/Footer';
import QuoteForm from './components/quote/QuoteForm';
import FAQ from './components/faq/FAQ';
import ContactDetails from './components/quick_actions/ContactDetails';
import Services from './components/srv/Services';
import './App.css';
import ThankPage from './components/thank_page/ThankPage';
import Copyright from './components/footer/Copyright';

function App() {
  return (
    <Router>
      <Routes>

        <Route path='/' element={
          <>
            <Nav/>
            <Hero/>
            <ServicesSummary/>
            <Statistics/>
            <Reviews props={[true, '/quote']}/>
            <Gallery/>
            <ReadyToBook props={[true, '/quote']}/>
            <Footer/>
          </>
        }/>

        <Route path='/quote' element={ 
          <>
            <Nav/>
            <QuoteForm expanded={false}/>
            <ContactDetails/>
            <Reviews props={[false, '/quote']}/>
            <FAQ backgroundWhite={false}/>
            <Footer/>
          </>
        }/>

        <Route path='/sm-quote' element={
          <>
            <QuoteForm expanded={true}/>
            <ServicesSummary/>
            <Statistics/>
            <Reviews props={[false, '/sm-quote#qf04']}/>
            <FAQ backgroundWhite={false}/>
            <ReadyToBook props={[false, '/sm-quote#qf04']}/>
            <Copyright/>
          </>
        }/>

        <Route path='/quote/thanks' element={
          <>
            <Nav/>
            <ThankPage/>
            <Footer/>
          </>
        }/>

        <Route path='/services-faq' element={
          <>
            <Nav/>
            <Services/>
            <FAQ backgroundWhite={true}/>
            <ReadyToBook props={[true, '/quote']}/>
            <Footer/>
          </>
        }/>

        <Route path='*' element={ 
          <>
            <Nav/>
            <NotFound/>
          </> 
        }/>
        
        <Route path='/admin' element={
          <>
            <Nav/>
            <Login/>
            <Footer/>
          </>
        }/>

        <Route path='/pay' element={
          <>
            <Nav/>
            <Pay/>
            <Footer/>
          </>
        }/>

        <Route path='/pay/verify' element={
          <>
            <Nav/>
            <PaySuccess/>
          </>
        } />

        <Route path='/privacy-policy' element={
          <>
            <Nav/>
            <PrivacyPolicy/>
            <Footer/>
          </>
        }/>

        <Route path='/terms-and-conditions' element={
          <>
            <Nav/>
            <TermsAndConditions/>
            <Footer/>
          </>
        }/>

        <Route path='/cookie-policy' element={
          <>
            <Nav/>
            <Cookies/>
            <Footer/>
          </>
        }/>

        <Route path='/user-agreement' element={
          <>
            <Nav/>
            <UserAgreement/>
            <Footer/>
          </>
        }/>

        <Route path='/disclaimer' element={
          <>
            <Nav/>
            <Disclaimer/>
            <Footer/>
          </>
        }/>

      </Routes>
    </Router>
  )
}

export default App;
