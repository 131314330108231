import './FAQ.css'
import $ from 'jquery';

const qa = (question: string, answer: string, seq: number) => {
    return (
        <div id={`faqq${seq}`} onClick={handleQA} key={seq}>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <span className='faq-title'>{question}</span>
                <span id={`faqq${seq}s`} className='faq-trigger-icon'>+</span>
            </div>
            <div id={`faqq${seq}a`} className='faq-answer'>
                <span>{answer}</span>
            </div>
        </div>
    )
}

const qaBlock = (start: number, end: number, questions: string[], answers: string[]) => {
    return(
        <>
            {
                questions.slice(start,end).map( (element, index) => {
                    return qa(element,answers[start+index],start+index)
                })
            }
        </>
    )
}

const constructQA = (questions: string[], answers: string[]) => {
    const leftBlock = [0,Math.round((questions.length) / 2)]
    const rightBlock = [Math.round((questions.length) / 2), questions.length]

    return(
        <>
            <div className='d-flex flex-column align-items-center'>
                {qaBlock(leftBlock[0],leftBlock[1],questions,answers)}
            </div>
            <div className='d-flex flex-column align-items-center'>
                {qaBlock(rightBlock[0],rightBlock[1],questions,answers)}
            </div>
        </>
    )
}

const handleQA = (e:any) => {
    const eID = e.currentTarget.id;
    const signElem = document.getElementById(eID + 's');
    if(signElem!.textContent === '+') {
        signElem!.textContent = '-';
        $(`#${eID}a`).slideToggle(600, 'swing');
    } else {
        signElem!.textContent = '+';
        $(`#${eID}a`).slideToggle();
    }
}

function FAQ({backgroundWhite}: any) {
    return (
        <section className={backgroundWhite ? 'faq-parent COLOR-GH93 px-4 d-flex flex-column align-items-center' : 'faq-parent COLOR-GH93-ALT px-4 d-flex flex-column align-items-center'} id='hash-faq'>
            <span className={backgroundWhite ? 'display-5 py-5 COLOR-GL1' : 'display-5 py-5 COLOR-GL1-ALT'}>Frequently Asked Questions</span>
            <div className='faq-sub-parent my-3 mb-5'>
                {constructQA([
                    'What detergents do you use?/Are your detergents child-safe and pet-safe?',
                    'What is the availability of the services?',
                    'How can I change my booking?',
                    'Do you work on bank holidays?',
                    'How do I book a service?',
                    'How long does it takes for sofa/car seats to dry?',
                    'How do emergency bookings work?',
                    'Do you cover congestion charge?',
                    'How can I pay?',
                    'How long does it takes for a carpet to dry?',
                    'How often should I clean my carpet?'
                ],[
                    'The detergents we use are Prochem and Solvent and they are child-safe and pet-safe.',
                    'We are available Monday-Friday from 8am to 5pm and Saturday&Sunday 9am to 4:30pm for regular bookings, and 24 hours for emergency bookings.',
                    'You can change your booking by calling us at 0333 090 1464 or emailing us (office@allcitycleaning.co.uk).',
                    'Yes. We work on holidays at no extra charge.',
                    'To book a service you can call us on 0333 090 1464 or you can send us a quick no-obligation free quote.',
                    'It takes between 10 and 24 hours depending on the material. It is recommended that the material/car is kept ventilated for a few hours to speed up the drying process.',
                    'Emergency booking is one that requires immediate action and/or is outside of our normal working hours. Every emergency service has £50 charge.',
                    'No. Any additional charges to a service such as congestion charge and parking fees are covered by the customer.',
                    'We accept card and cash payments. For some services we offer the option to split the cost of service over the period of 3 months.',
                    'It depends on the material of the carpet, however usually it takes about 5 hours.',
                    'It is recommended that you clean your carpet once to twice a year to maintain it clean. This can vary if higher hygiene standards are required or if there are kids or pets.'
                ])}
            </div>
        </section>
    )
}

export default FAQ;