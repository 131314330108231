import './Pay.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useEffect, useRef, useState } from 'react';
import axios from '../../api/axios';
import { loadStripe } from '@stripe/stripe-js';

function Pay() {
    const errRef:any = useRef();
    const [paymentCode, setPaymentCode] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setErrMsg('');
    }, [paymentCode])

    const handlePayment = async (e:any) => {
        e.preventDefault();

        if(paymentCode.length > 0) {
            const response = await axios.post(
                '/pay',
                {
                    code: paymentCode.toUpperCase()
                }
            );

            if(response.data.status === 'notfound') {
                setErrMsg('Payment code expired or not valid.');
                errRef.current.focus();
            } else if(response.data.status === 'paid') {
                setErrMsg('Payment code already paid.');
                errRef.current.focus();
            } else {
                const stripe = await loadStripe(response.data.key);
                stripe?.redirectToCheckout({
                    sessionId: response.data.id
                })
            }
        } else {
            setErrMsg('Please enter a valid payment code.');
            errRef.current.focus();
        }
    } 

    return(
        <section className='COLOR-ZB d-flex flex-column px-5 py-4 H-100VH'>
            <span className='py-3 display-5'>AllCityCleaning Payment Portal</span>
            <span className='p-instruction'>Here you can pay your bookings, parking, congestion charge and more.</span>
            
            <form className='d-flex flex-column py-3 my-4'>
                <span className='p-form-label'>Enter payment code:</span>
                <div className='d-flex flex-row align-items-baseline my-3'>
                    <input 
                        type="text"
                        placeholder='ABCD1234'
                        value={paymentCode}
                        onChange={(e) => setPaymentCode(e.target.value)}
                        className='p-input'
                    />
                    <button onClick={handlePayment} className='p-button mx-3'>Pay</button>
                </div>
                <span ref={errRef} className={errMsg ? "on p-err" : "off p-err"} aria-live="assertive">{errMsg}</span>
            </form>
        </section>
    )
}

export default Pay;