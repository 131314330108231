import 'bootstrap/dist/css/bootstrap.css';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
    return (
        <section className="G-1 px-2">
            <h1 className='py-3 display-2'>Privacy Policy</h1>
            <span className='last-updated'>This document was last updated on November 22, 2022</span>

            <div className='d-flex flex-column mt-5 mb-3 mx-2 p-2 index-table'>
                <span>Sections of this policy</span>
                <a href='#ppsec1'>What private data do we collect from the people who visit our website?</a>
                <a href='#ppsec2'>Automatically collected information about your use of our Services or tools</a>
                <a href='#ppsec3'>How do we handle social signals?</a>
                <a href='#ppsec4'>Do Not Track</a>
                <a href='#ppsec5'>How do we use your details?</a>
                <a href='#ppsec6'>GDPR-Customer data processing appendix</a>
                <a href='#ppsec7'>GDPR-EU data protection law</a>
                <a href='#ppsec8'>Your Legal Rights</a>
                <a href='#ppsec9'>How do we protect your details?</a>
                <a href='#ppsec10'>Can-spam act</a>
                <a href='#ppsec11'>Limitation of liability</a>
                <a href='#ppsec12'>Indemnification</a>
                <a href='#ppsec13'>Governing Law and Jurisdiction</a>
                <a href='#ppsec14'>Changes to this privacy notice</a>
                <a href='#ppsec15'>Contacting us</a>
            </div>

            <p className='py-3 mb-0'>
            We know that in this digital age, your privacy is important. This Privacy Policy reflects our commitment to protect personal data and the choices we offer you regarding how your data is used. We welcome you to read more about how we keep your information safe, as well as how you can exercise your rights. In addition, our Privacy policy covers our treatment of data that may be personal to you.
            </p>


            <ul>
                <li>
                We will review, update, and amend these policies from time to time consistent with our business needs and technology. We encourage you to check back periodically for new updates or changes. Your continued use of the service makes up your acceptance of any change to this Privacy Policy. We are the data controller of your information. We handle and process all data on behalf of our customers.
                </li>
                <li>
                You may likewise decide not to give us "discretionary" Personal Data; however please remember that without it, we will most likely be unable to provide you with the full scope of our administrations or with the best client experience when utilizing our Services.
                </li>
                <li>
                This Privacy Policy <b>(“Privacy Policy”)</b> describes how <b>All City Cleaning</b> will gather, use, and maintain your Personal Information on the allcitycleaning.co.uk. It will also explain your legal rights with respect to that information.
                </li>
                <li>
                By using the AllCityCleaning website or services, you confirm that you have read and understand this Privacy Policy, and our Terms (together referred to herein as the <b>“Agreement”</b>). The Agreement governs the use of <b><u>allcitycleaning.co.uk</u></b>. AllCityCleaning will collect, use, and maintain information consistent with the Agreement.
                </li>
            </ul>


            <p id="ppsec1" className='display-6 pt-4'><i><b>What private data do we collect from the people who visit our website?</b></i></p>
            <p>When enlisting on our site or buying services, as suitable, you could be approached to type in your name, email, or different subtleties to assist you with your experience.</p>
            <p>When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:</p>
            <ul>
                <li>
                <b>Account Data</b>: To use certain features (like Managing your booking), you need to create a user account. When you create or update your account, we collect and store the data you provide, like your email address, first name, last name and assign you a unique identifying number ("Account Data").
                </li>
                <li>
                <b>Personal Data</b>: Personal Data is information that can be used to identify you specifically, including your name, email address, telephone number, or demographic information like your hometown. You consent to give us this information by providing it to us voluntarily on our website or any mobile application. Your decision to disclose this data is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain benefits from our website.
                </li>
                <li>
                <b>For workers of website</b>: All the above including Job title, Account information linked to our third party provider (Stripe), that we use to manage payments, and history of all previous and ongoing jobs.
                </li>
                <li>
                <b>Financial Data</b>: Financial data is related to your payment methods, such as credit card or bank transfer details. We collect financial data to allow you to purchase, or exchange services from our website. We store limited financial data. Most financial data is transferred to our payment processor, Stripe, and you should review these processors' Privacy Policy to determine how they use, disclose, and protect your financial data. 
                </li>
                <ul>
                    <li>
                    Stripe Privacy Policy can be found <a href="https://stripe.com/gb/privacy" target="_blank" rel="noreferrer">here</a>
                    </li>
                </ul>
                <li>
                <b>Contact information</b>: An Authorized User is required to provide some contact information (e.g., an email address) when making an account on the Services.
                </li>
                <li>
                <b>Minors' Data</b>: We do not knowingly collect data from or market to children under 13 years of age. We do not knowingly solicit data from or market to children under 13 years of age. By using the Website, you represent that you are at least 13 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Website. If we learn that personal information from users less than 13 years of age has been collected, We will take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 13, please contact us.
                </li>
            </ul>


            <p id="ppsec2" className='display-6 pt-4'><i><b>Automatically collected information about your use of our Services or tools</b></i></p>
            <p>This information is registered automatically with the visit by own configuration or manual of each tool on the website.</p>
            <ul>
                <li>
                When you visit, connect with, or utilize our service, we may gather, record, or create specific specialized data about you. We do so either autonomously or with the assistance of third gathering Service Providers, including using <b>"cookies"</b> and other following innovations.
                </li>
                <li>
                We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.
                </li>
            </ul>


            <p id="ppsec3" className='display-6 pt-4'><i><b>How do we handle social signals?</b></i></p>
            <p>If you choose to register or log in to our website using a social media account, we may access certain information about you.</p>
            <p>Our Sites offers you the ability to register and log in using your third-party social media account details (like your Facebook, Google, or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, profile picture, as well as other information you choose to make public.</p>
            <p>We will use the information we receive only for the purposes described in this privacy policy or that are otherwise made clear to you on the Sites. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy policy to understand how they collect, use, and share your personal information and how you can set your privacy preferences on their sites and apps.</p>


            <p id="ppsec4" className='display-6 pt-4'><i><b>Do Not Track</b></i></p>
            <p>Currently, various browsers — such as Internet Explorer, Firefox, and Safari — offer a "do not track" or "DNT" option that relies on a technology known as a DNT header, which sends a signal to Web sites' visited by the user about the user's browser DNT preference setting. AllCityCleaning does not currently commit to responding to browsers' DNT signals with respect to the Company's Web sites, in part, because no common industry standard for DNT has been adopted by industry groups, technology companies or regulators, including no consistent standard of interpreting user intent. AllCityCleaning takes privacy and meaningful choice seriously and will make efforts to continue to monitor developments around DNT browser technology and the implementation of a standard.</p>

            <p id="ppsec5" className='display-6 pt-4'><i><b>How do we use your details?</b></i></p>
            <p>We process personal data to operate, improve, understand, and personalize our services. We use personal data for the following purposes:</p>
            <ul>
                <li>To meet or fulfill the reason you provided the information to us.</li>
                <li>To communicate with you about the Services, including Service announcements, updates or offers.</li>
                <li>Correspond with you; and</li>
                <li>Compile anonymous statistical data for our own use or for a third party's use; and</li>
                <li>Assist law enforcement as necessary; and</li>
                <li>Prevent fraudulent activity on our website or mobile app; and</li>
                <li>Analyze trends to improve our website and offerings.</li>
                <li>To fulfill or meet the reason you provided the information (e.g., to help provide our Site services to you).</li>
                <li>To personalize and develop our site and the services we provide you and improve our offerings.</li>
                <li>To provide certain features or functionality of the services on the site.</li>
                <li>For marketing and promotions.</li>
                <li>To create, maintain, customize, and secure your account with us.</li>
                <li>To provide you with support, to communicate with you and respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</li>
                <li>To personalize your experience and to deliver content and services relevant to your interests.</li>
                <li>To help maintain the safety, security, and integrity of our site, services, databases, and other technology assets and business.</li>
                <li>For testing, research, analysis, and website development, including to develop and improve our site and services.</li>
                <li>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                <li>To prevent illegal activity, fraud, and abuse.</li>
                <li>Where we must play out the agreement we are going to go into or have gone into with you.</li>
                <li>To help our site that will be ready to serve you better.</li>
            </ul>
            <p>We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
            <p>As noted in the list above, we may communicate with you if you've provided us the means to do so. For example, if you've given us your email address, we may send you promotional email offers or email you about your use of the Services. Also, we may receive a confirmation when you open an email from us, which helps us improve our services. If you do not want to receive communications from us, please indicate your preference by emailing us at <b><u>office@allcitycleaning.co.uk.</u></b></p>


            <p id="ppsec6" className='display-6 pt-4'><i><b>GDPR-Customer data processing appendix</b></i></p>
            <p><b>"Customer Data"</b> means any personal data that AllCityCleaning processes on behalf of Customer via the Services, as more particularly described in this DPA.</p>
            <p><b>"Data Protection Laws"</b> means all data protection laws and regulations applicable to a party's processing of Customer Data under the Agreement, including, where applicable, EU Data Protection Law and Non-EU Data Protection Laws.</p>


            <p id="ppsec7" className='display-6 pt-4'><i><b>GDPR-EU data protection law</b></i></p>
            <p><b>"EU Data Protection Law"</b> means all data protection laws and regulations applicable to Europe, including (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (<b>General Data Protection Regulation</b>) (<b>"GDPR"</b>); (ii) Directive 2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications sector; (iii) applicable national implementations of (i) and (ii); and (iv) in respect of the United Kingdom ("UK") any applicable national legislation that replaces or converts in domestic law the GDPR or any other law relating to data and privacy as a consequence of the UK leaving the European Union.</p>
            <p><b>"Europe"</b> means, for the purposes of this DPA, the European Union, the European Economic Area and/or their member states, Switzerland, and the United Kingdom</p>
            <p><b>"Sensitive Data"</b> means (a) social security number, passport number, driver's license number, or similar identifier (or any portion thereof); (b) credit or debit card number (other than the truncated (last four digits) of a credit or debit card); (c) employment, financial, genetic, biometric or health information; (d) racial, ethnic, political or religious affiliation, trade union membership, or information about sexual life or sexual orientation; (e) account passwords; or (f) other information that falls within the definition of "special categories of data" under applicable Data Protection Laws.</p>
            <p><b>"Services Data"</b> means any data relating to the Customer's use, support and/or operation of the Services, including information relating to volumes, activity logs, frequencies, bounce rates or other information regarding emails and other communications Customer generates and sends using the Services.</p>
            <ul>
                <li>
                <b>Parties' roles</b>: If EU Data Protection Law or the LGPD applies to either party's processing of Customer Data, the parties acknowledge and agree that concerning the processing of Customer Data, Customer is the controller and is a processor acting on behalf of Customer, as further described in Annex A (Details of Data Processing) of this DPA. 
                </li>
                <li>
                <b>Purpose limitation</b>: AllCityCleaning shall process Customer Data only following Customer's documented lawful instructions as outlined in this DPA, as necessary to comply with applicable law, or as otherwise agreed in writing ("Permitted Purposes"). The parties agree that the agreement sets out the Customer's complete and final instructions to AllCityCleaning concerning the processing of Customer Data, and processing outside the scope of these instructions (if any) shall require a prior written agreement between the parties.  
                </li>
                <li>
                <b>Prohibited data</b>: Customer will not provide (or cause to be provided) any Sensitive Data to AllCityCleaning for processing under the Agreement, and AllCityCleaning will have no liability whatsoever for Sensitive Data, whether in connection with a Security Incident or otherwise. For the avoidance of doubt, this DPA will not apply to Sensitive Data.
                </li>
                <li>
                <b>Customer compliance</b>: Customer represents and warrants that (i) it has complied, and will continue to comply, with all applicable laws, including Data Protection Laws, in respect of its processing of Customer Data and any processing instructions it issues to AllCityCleaning; and (ii) it has provided, and will continue to provide, all notice and has obtained, and will continue to obtain, all consents and rights necessary under Data Protection Laws for AllCityCleaning to process Customer Data for the purposes described in the agreement. Customer shall have sole responsibility for the accuracy, quality, and legality of Customer Data and how Customer acquired Customer data. Without prejudice to the generality of the foregoing, Customer agrees that it shall be responsible for complying with all laws (including Data Protection Laws) applicable to any emails or other content created, sent, or managed through the service, including those relating to obtaining consents (where required) to send emails, the content of the emails and its email deployment practices.
                </li>
                <li>
                <b>The lawfulness of Customer's instructions</b>: Customer will ensure that United Kingdom processing of the Customer Data by Customer's instructions will not cause AllCityCleaning to violate any applicable law, regulation, or rule, including, without limitation, Data Protection Laws. AllCityCleaning shall promptly notify Customer in writing unless prohibited from doing so under EU Data Protection Laws if it becomes aware or believes that any data processing instruction from Customer violates the GDPR or any UK implementation of the GDPR.
                </li>
            </ul>


            <p id="ppsec8" className='display-6 pt-4'><i><b>Your Legal Rights</b></i></p>
            <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data. You may have the following rights:</p>
            <ol type="A">
                <li>
                <b>Request access</b> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. 
                </li>
                <li>
                <b>Request correction</b> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                </li>
                <li>
                <b>Request deletion</b> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                </li>
                <li>
                <b>Object to processing</b> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                </li>
                <li>
                <b>Request restriction</b> of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                <ol type="a">
                    <li>If you want us to establish the data's accuracy.</li>
                    <li>Where our use of the data is unlawful, but you do not want us to erase it.</li>
                    <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
                    <li>You have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it.</li>
                </ol>
                </li>
                <li>
                <b>Request the transfer</b> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                </li>
                <li>
                <b>Withdraw consent</b> at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you.
                </li>
            </ol>


            <p id="ppsec9" className='display-6 pt-4'><i><b>How do we protect your details?</b></i></p>
            <ul>
                <li>
                We have implemented industry-accepted administrative, physical, and technology-based security measures to protect against loss, misuse, unauthorized access, and alteration of personal information in our systems. We ensure that any employee, contractor, corporation, organization, or vendor who has access to personal information in our systems is subject to legal and professional obligations to safeguard that personal information.
                </li>
                <li>
                We do not use vulnerability scanning and/or scanning to PCI specifications.
                </li>
                <li>
                We use regular Malware Scanning.  
                </li>
                <li>
                Your individual information is comprised behind secured systems and is merely accessible by a restricted number of folks who've special access privileges to such systems and must keep the information confidential carefully. Furthermore, all very sensitive/credit information you resource is encrypted via Secure Socket Layer (SSL) technology.
                </li>
                <li>
                We implement several security measures whenever a user gets into, submits, or accesses their information to keep up the protection of your individual information.
                </li>
                <li>
                While we strive to use commercially acceptable means to protect your personal information, no method of transmission over the Internet or form of electronic storage is 100 percent secure. Therefore, we cannot guarantee its absolute security.
                </li>
                <li>
                AllCityCleaning prohibits unauthorized access or use of personal information stored on our servers. Such access is a violation of law, and we will fully investigate and press charges against any party that has illegally accessed information within our systems.
                </li>
            </ul>


            <p id="ppsec10" className='display-6 pt-4'><i><b>Can-spam act</b></i></p>
            <p>The CAN-SPAM Act is a regulation that sets the guidelines for commercial email, establishes requirements for commercial announcements, offers recipients to have emails ceased from being delivered to them, and spells out hard fines for violations.</p>
            <p>We accumulate your email to be able to:</p>
            <ul>
                <li>Send information, react to questions, and/or other demands or questions.</li>
                <li>To maintain compliance with CANSPAM, we consent to the next:</li>
                <li>Not use untrue or misleading subject matter or email addresses.</li>
                <li>Identify the concept as an advertisement in some realistic way.</li>
                <li>Include the physical address of our site headquarters or business.</li>
                <li>Screen third-party email marketing services for conformity, if one can be used.</li>
                <li>Honor opt-out/unsubscribe demands quickly.</li>
                <li>Allow users to unsubscribe utilizing the link at the bottom of every email.</li>
            </ul>
            <p>If anytime you want to unsubscribe from receiving future email, you can email us at by using contact form at our website allcitycleaning.co.uk and we'll immediately remove you from ALL communication.</p>


            <p id="ppsec11" className='display-6 pt-4'><i><b>Limitation of liability</b></i></p>
            <ul>
                <li>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you.</li>
                <li>We make no legal representation that the website or services are appropriate or available for use in locations outside United Kingdom. You may access the website from outside United Kingdom at your own risk and initiative and must bear all responsibility for compliance with any applicable foreign laws.</li>
            </ul>


            <p id="ppsec12" className='display-6 pt-4'><i><b>Indemnification</b></i></p>
            <ul><li>Upon visiting this website you agree release, indemnify, defend and hold harmless AllCityCleaning and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of your use of the website content; your use of the services; your violation of any provision of these terms; any unauthorized information or data you supplied to us. You will have sole responsibility to defend us against any claim, but you must receive our prior written consent regarding any related settlement.</li></ul>


            <p id="ppsec13" className='display-6 pt-4'><i><b>Governing Law and Jurisdiction</b></i></p>
            <ul><li>This website originates from United Kingdom. The laws of United Kingdom without regard to its conflict of law principles will govern these terms to the contrary. You, hereby agree that all disputes arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the United Kingdom. By using this website, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding, or claim arising under or by reason of these terms. You hereby waive any right to trial by jury arising out of these terms.</li></ul>


            <p id="ppsec14" className='display-6 pt-4'><i><b>Changes to this privacy notice</b></i></p>
            <p>We're constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time as well, but we will alert you to changes by placing a notice on the AllCityCleaning website, by sending you an email, and/or by some other means. Please note that if you've opted not to receive legal notice emails from us (or you haven't provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>


            <p id="ppsec15" className='display-6 pt-4'><i><b>Contacting us</b></i></p>
            <p>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may do so by email at <b><u>office@allcitycleaning.co.uk</u></b> or call us at <b>+44 (0) 333 090 14 64</b>.</p>
            <p><b>AllCityCleaning</b></p>
            <p>London, United Kingdom</p>
        </section>
    )
}

export default PrivacyPolicy;