import React from 'react';

function Copyright() {
    return (
        <footer className='px-4 py-1 d-flex justify-content-center'>
            <span>AllCityCleaning ©2020-2023. ALL RIGHTS RESERVED.</span>
        </footer>
    )
}

export default Copyright;