import React, { useEffect, useRef, useState } from 'react';
import './Login.css';
import axios from '../../api/axios';
import Admin from '../admin_page/Admin';
import timeout from '../static/timeout';

function Login() {
    const userRef:any = useRef();
    const errRef:any = useRef();
    const jwtRef:any = useRef();

    const [varAccessToken, setAccessToken]  = useState('');
    const [varRefreshToken, setRefreshToken]  = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])
    
    useEffect(() => {
        if(success) {
            const interval = setInterval(() => {
                const data:any = refreshToken();
                setAccessToken(data.accessToken)
                setRefreshToken(data.refreshToken)
            }, 55*1000);
            jwtRef.current = interval;
            return () => clearInterval(interval)
        }
    })


    const refreshToken = async () => {
        try {
            const res = await axios.post('/auth/refresh',{}, {
                headers: {
                    token: varRefreshToken
                }
            });

            if(res.status === 200) {
                setAccessToken(res.data.accessToken);
                setRefreshToken(res.data.refreshToken);
            }
            return res?.data;
        } catch (error) {
            console.log(error)
        }
    }

    const handleLogin = async (e:any) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                '/auth/login',
                JSON.stringify({
                    username, 
                    password}),
                {
                    headers: {'Content-Type': 'application/json'}
                }
            )
            if(response.status === 200) {
                setSuccess(true);
                setAccessToken(response.data.accessToken);
                setRefreshToken(response.data.refreshToken);
                setUsername('');
                setPassword('');
            }
        } catch (error) {
            setErrMsg('Login failed. Try again.');
            errRef.current.focus();
        }
        
    }

    const handleTestClick = async (e:any) => {
        document.getElementById('token-tester')!.innerText = 'Starting test...';
        e.preventDefault();

        await timeout(1000);
        try {
            const response = await axios.post(
                '/auth/test',
                JSON.stringify({
                    token: varAccessToken
                }),
                {
                    headers: {'Content-Type': 'application/json'}
                }
            )
            document.getElementById('token-tester')!.innerText = response.data;
        } catch (error) {
            document.getElementById('token-tester')!.innerText = 'Test failed!';
        }
    }

    const handleLogout = async () => {
        clearInterval(jwtRef.current);
        const res = await axios.post(
            '/auth/logout', 
            JSON.stringify({
                token: varAccessToken
            }),
            {
                headers: {'Content-Type': 'application/json'}
            }
        );
        
        if(res.status === 200) {
            setSuccess(false);
            setAccessToken('');
            setRefreshToken('');
            setErrMsg('You sign out.');
        }
    }

    return(
        <>
            {success ? (
                <section className='COLOR-OP'>
                    <div className='d-flex flex-column align-items-start mx-4'>
                        <button id='token-tester' onClick={handleTestClick} className='my-2'>Test token</button>
                        <button onClick={handleLogout} className='my-2'>Log out</button>
                    </div>
                    <Admin authToken={varAccessToken} />
                </section>
            ) : (
                <section className='login-parent GLOBAL-A001B'>
                    <form onSubmit={handleLogin}>
                        <h3>Sign in</h3>
                        <input 
                            ref={userRef} 
                            type='text' 
                            placeholder='Username' 
                            onChange={(e) => setUsername(e.target.value)} 
                            value={username} 
                            required
                            autoComplete='off'
                            className='my-2'
                        />
                        <input 
                            type='password'
                            placeholder='Password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            className='my-2'
                        />
                        <button className='my-2'>Log in</button>
                        <span ref={errRef} className={errMsg ? "on ec" : "off ec"} aria-live="assertive">{errMsg}</span>
                    </form>
                </section>
            )
            }
        </>
    )
}

export default Login;