import React from 'react';
import './Services.css';
import 'bootstrap/dist/css/bootstrap.css';

const block = (title: string, desc: string, alignLeft: boolean) => {
    return (
        <>
            <div className={alignLeft ? 'facts-left services-card px-3 py-3' : 'facts-right services-card px-3 py-3'}>
                <span className='main COLOR-AKL'>{title}</span>
                <span className='sub COLOR-AKL'>{desc}</span>
            </div>
        </>
    )
}

function Services() {
    return (
        <section className='py-4 COLOR-KLL d-flex flex-column align-items-center' id='hash-services'>
            <div className='services-title py-2 pb-4'>
                <span className='display-5 COLOR-AKL'>What we offer</span>
            </div>

            <div className='services-card-parent py-3 pb-4'>
                <div>
                    {block('Carpets',`
                    Make your carpets look as good as new, small or big.
                    `,true)}
                    {block('Rooms', `
                    We clean your most important rooms - Through Lounge, 
                    Living Room, Dining room, and any other bedrooms.`, true)}
                    {block('Staircase', `Clean the Staircases and Landings in
                    your house.`, true)}
                    {block('Mattress', `Who wouldn't want to clean what they
                    sleep on?`, true)}
                    {block('Curtains/Blinds', `Your curtains and blinds are
                    equaly important to clean.`, true)}
                </div>
                <div>
                    {block('Sofa/Armchair', `Whether you have a small armchair
                    or a big sofa we clean it all.`, false)}
                    {block('Bedframe/Headboard', `We clean every angle of your bed.`, false)}
                    {block('Chairs', `Get your kitchen chairs or computer chair
                    cleaned.`, false)}
                    {block('Cushions', `We love our cushions. Why not keep them
                    fresh and clean.`, false)}
                    {block('Commercial cleaning', `We offer cleaning to businesses from
                    schools and offices to transport vehicles.`, false)}
                </div>
            </div>
        </section>
    )
}

export default Services;