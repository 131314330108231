import React from 'react';
import './NotFound.css';

function NotFound() {
    return (
        <section className='px-4 py-5 GLOBAL-A001B'>
            <span className='display-5'>Ooops! We could not find that page.</span>
            
            <div className='py-4'>
                <a className='no-href-effect' href='/'>
                    <button className='tp-button'>Home page</button>
                </a>
            </div>
        </section>
    )
}

export default NotFound;