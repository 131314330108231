import 'bootstrap/dist/css/bootstrap.css';
import './TermsAndConditions.css';

function TermsAndConditions() {
    return(
        <section className="G-1 px-2">
            <h1 className='py-3 display-2'>Terms & Conditions</h1>
            <span className='last-updated'>This document was last updated on November 22, 2022</span>

            <div className='d-flex flex-column mt-5 mb-3 mx-2 p-2 index-table'>
                <span>Sections of this document</span>
                <a href='#tacsec1'>DEFINITIONS</a>
                <a href='#tacsec2'>CONTRACT</a>
                <a href='#tacsec3'>QUOTATIONS</a>
                <a href='#tacsec4'>VAT</a>
                <a href='#tacsec5'>EQUIPMENT</a>
                <a href='#tacsec6'>PAYMENT</a>
                <a href='#tacsec7'>CANCELLATION</a>
                <a href='#tacsec8'>REFUNDS</a>
                <a href='#tacsec9'>COMPLAINTS</a>
                <a href='#tacsec10'>CLAIMS</a>
                <a href='#tacsec11'>SUPPLEMENTARY TERMS</a>
                <a href='#tacsec12'>MODIFICATION & VARIATION</a>
                <a href='#tacsec13'>ENTIRE AGREEMENT</a>
                <a href='#tacsec14'>SERVICE INTERRUPTIONS</a>
                <a href='#tacsec15'>TERM, TERMINATION & SUSPENSION</a>
                <a href='#tacsec16'>NO WARRANTIES</a>
                <a href='#tacsec17'>LIMITATION ON LIABILITY</a>
                <a href='#tacsec18'>GENERAL PROVISIONS</a>
            </div>

            <p><b>THE AGREEMENT</b>: The use of this website and services on this website provided by AllCityCleaning (hereinafter referred to as "Website") are subject to the following Terms & Conditions, all parts, and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the use of all pages on this website (hereinafter collectively referred to as "Website") and any services provided by or on this Website ("Services").</p>


            <p id="tacsec1" className='display-6 pt-4'><i><b>DEFINITIONS</b></i></p>
            <p><b>"Agreement"</b> denotes to this Terms and Conditions and the Privacy Policy and other documents provided to you by the Website.</p>
            <p><b>"The Company"</b>, <b>"We"</b>, <b>"Us"</b> <b>- means</b> AllCityCleaning</p>
            <p><b>"Cleaner"</b>, <b>"Cleaning Operative"</b> <b>- means</b> the person or firm providing cleaning service on behalf of the Company.</p>
            <p><b>"Customer"</b> - means the person or corporate body together with any subsidiary or associated Company as defined by the Companies Act 2006 to whom the cleaning service are supplied by the Company.</p>
            <p><b>"Customer's Address"</b> - means the address where the Customer has requested the cleaning services to be carried out.</p>
            <p><b>"Service"</b>, <b>"Professional Cleaning"</b> means the cleaning services provided on behalf of the Company.</p>
            <p><b>"Cleaning Visit"</b> - means the visit to the Customer's service address by the Cleaner to provide the Service.</p>
            <p>Unless the context requires otherwise, reference to the singular includes the plural, and references to the masculine include the feminine and vice versa.</p>


            <p id="tacsec2" className='display-6 pt-4'><i><b>CONTRACT</b></i></p>
            <p>These Terms and Conditions represent a contract between AllCityCleaning and the Customer.</p>
            <ul>
                <li>
                Both parties will ensure that their respective responsibilities under this Agreement are undertaken in compliance with all statutory regulations and codes of conduct.
                </li>
                <li>
                The Customer agrees that any use of the Company's cleaning service, including placing an order for services by phone, live chat, email, website forms, will constitute the Customer's acceptance of these Terms and Conditions.
                </li>
                <li>
                Unless otherwise agreed in writing by a manager of the Company, these Terms and Conditions will prevail over any other terms of business or purchase conditions put forward by the Customer.
                </li>
                <li>
                No variation or alteration of these Terms and Conditions will be valid unless approved in writing by a manager of the Company.
                </li>
            </ul>


            <p id="tacsec3" className='display-6 pt-4'><i><b>QUOTATIONS</b></i></p>
            <ul>
                <li>
                All quotations are given by the Company following a request by the Customer and will remain open to acceptance for a period of 30 days from their date. 
                </li>
                <li>
                We reserve the right to amend the initial quotation, should the Customer's original requirements change.
                </li>
            </ul>


            <p id="tacsec4" className='display-6 pt-4'><i><b>VAT</b></i></p>
            <p>Any shown prices <b>include</b> VAT.</p>


            <p id="tacsec5" className='display-6 pt-4'><i><b>EQUIPMENT</b></i></p>
            <ul>
                <li>
                The Company will provide all cleaning supplies and carpet cleaning equipment required to provide the cleaning services.
                </li>
                <li>
                The Customer must provide running water and electricity at the premises where the cleaning services take place.
                </li>
                <li>
                The Customer has to either provide a parking space for the Cleaner or pay for such. Cleaner should provide a receipt to Customer should they choose to pay for parking.
                </li>
            </ul>


            <p id="tacsec6" className='display-6 pt-4'><i><b>PAYMENT</b></i></p>
            <ul>
                <li>
                The Customer must make payment online after booking or in cash before the cleaner leaves the Customer's property.
                </li>
                <li>
                Although greatly appreciated and a powerful way to say, 'Thank you', the Customer understands that tipping is not required.
                </li>
                <li>
                We reserve the right to cancel any contract and back charge additional for past services to reflect the balance of the standard rate if any misleading or false information was used to obtain discounted cleaning service or if the given general requirements for the service and its professional performance would not be possible.
                </li>
                <li>
                The rates of payment by the Company will be as agreed between the Company and the Customer, or his representative. The Customer will make no reduction or retention from the sum due under any invoice.
                </li>
            </ul>


            <p id="tacsec7" className='display-6 pt-4'><i><b>CANCELLATION</b></i></p>
            <ul>
                <li>
                The Customer can cancel the scheduled services by giving no less than 24 hours prior notice in writing. If the service is booked with less than 24-hours from when it takes place, the Client waives the right to cancel the booking. In case the service is canceled, the Company requires the customer to pay cancellation fee (£30). Any remaining funds will be debited back to the customer's account within 7 business days. If the Customer reschedules the service to a different day & time, no cancellation/rescheduling fee is required.
                </li>
                <li>
                The Customer must pay the full price of the booked service if:
                </li>
                <li>
                Our cleaners arrive at the Customer's address and are unable to gain access to the Customer's property, through no fault of the Company. If the customer or third party has prevented the cleaner from doing their job. If keys are provided, they must open all locks without any special effort or skills. 
                </li>
                <li>
                If the Customer reschedules the service upon the arrival of the Cleaning Operative onsite, the same should cover travel expenses to the Cleaning Operative.
                </li>
                <li>
                If the Customer needs to change the cleaning service or time the Company will do its best to accommodate him. Any changes to booked cleaning service are subject to a 24-hour prior notice and availability.
                </li>
            </ul>


            <p id="tacsec8" className='display-6 pt-4'><i><b>REFUNDS</b></i></p>
            <p>Refund will be issued only if:</p>
            <ul>
                <li>
                The Customer has canceled a cleaning service within the allowed time of 24 hours prior to the start of the cleaning service.
                </li>
                <li>
                A cleaner has not been able to provide the cleaning due to reasons beyond the Customer's responsibility.
                </li>
            </ul>


            <p id="tacsec9" className='display-6 pt-4'><i><b>COMPLAINTS</b></i></p>
            <ul>
                <li>
                We request that complaints or feedback be provided in writing (by letter or email) and include photographic evidence of the issued area and description of the issue within a reasonable time of cleaning services completion to ensure that the details are received in a clear and complete manner. Due to the nature of the service, a reasonable time is equal to up to 24 hours upon completion of the service.  
                </li>
                <li>
                All cleaning services will be deemed to have been provided to the Customer's satisfaction unless a written notice detailing the complaint is received by the Company within a reasonable time of services completion. The Company will fully investigate any complaints and attempt to resolve them to the satisfaction of the Customer, or alternatively to a reasonable standard.
                </li>
                <li>
                The Customer agrees to allow the Company back to re-clean any disputed areas or repair damaged items before making any attempts to clean those areas himself or arranging a third party to provide a cleaning or repair service with regards to the above. Failure to do so will consider the matter is fully settled.
                </li>
                <li>
                According to Consumer Rights Act 2015, a repeat performance can be requested only if reasonable and is not in conformity with our requirements for a professional performance of the service.
                </li>
                <li>
                Further to Consumer Rights Act 2015, the service should be performed with reasonable care and skills; however, if it is in conformity with our requirements for a professional performance of the service or the requested cleaning service is inapposite for the situation, the Company will not be liable for any future issues.
                </li>
            </ul>


            <p id="tacsec10" className='display-6 pt-4'><i><b>CLAIMS</b></i></p>
            <ul>
                <li>
                The Customer agrees that due to the nature of the cleaning services, the Company guarantees only to correct any problems reported within a reasonable time equal to 24 hours upon completion of the cleaning services. Failure to do so will entitle the Customer to nothing.
                </li>
                <li>
                We may require entry to the property of the claim as soon as possible to rectify the problem.
                </li>
                <li>
                The Customer agrees to inspect the work immediately upon the cleaning is done and to draw the cleaners' attention to any outstanding issues while they are still on site. The cleaners will provide any such additional work to the Customer's complete satisfaction.
                </li>
                <li>
                If the Customer or any third party instructed by the Customer is not present at the time of completion of the cleaning services to inspect the work, then no claims regarding any cleaning issues can be made.
                </li>
                <li>
                If the Customer instructs a third party to inspect the result from the cleaning service, then the Company must be notified before completion of the service.
                </li>
                <li>
                In case of a third party inspecting or refusing to inspect the result from the cleaning service, then the Company cannot be held responsible for rectifying any outstanding cleaning issues not mentioned by the third party.
                </li>
                <li>
                Any refunds or adjustments must be requested to Us directly and subject to approval by Us.
                </li>
                <li>
                The Customer waives his right to stop payment on his card or protest a Bank Transfer payment.
                </li>
                <li>
                While the Company cleaners make every effort not to break items, accidents do happen. Identical replacement is always attempted but not guaranteed. For this specific reason, We request all irreplaceable items (whether monetarily or sentimentally valuable) be stored away and /or not cleaned by the cleaners. 
                </li>
                <li>
                In case of confirmed damage caused by cleaners, the Company will attempt to repair the item at its cost. If the item can't be repaired, we will rectify the problem by crediting the client with the item's present actual cash value toward a like replacement from a Company's source upon payment of cleaning service rendered.
                </li>
                <li>
                We will not be responsible for damage due to faulty and/or improper installation of any item, also old/worn out/damaged items. All surfaces (i.e., marble, granite, wood, etc.) are assumed sealed and ready to clean without causing harm.
                </li>
            </ul>


            <p id="tacsec11" className='display-6 pt-4'><i><b>SUPPLEMENTARY TERMS</b></i></p>
            <ol id="tacsec11-sub" type='1'>
                <li>GENERAL CONDITION</li>
                <ul>
                    <li>
                    We do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us.
                    </li>
                    <li>
                    We make material changes to these terms and conditions from time to time; we may notify you either by prominently posting a notice of such changes or via email communication.
                    </li>
                    <li>
                    The website is licensed to you on a limited, non-exclusive, non-transferable, non-sublicensable basis, solely to be used in connection with the Service for your private, personal, non-commercial use, subject to all the terms and conditions of this Agreement as they apply to the Service.
                    </li>
                </ul>
                <br/>

                <li>LICENSE TO USE WEBSITE</li>
                <p>We may provide you with certain information because of your use of the Website or Services. Such information may include but is not limited to documentation, data, or information developed by us, and other materials which may assist in your use of the Website or Services ("Our Materials"). Subject to this Agreement, we grant you a non-exclusive, limited, non-transferable, and revocable license to use Our Materials solely in connection with your use of the Website and Services. Our Materials may not be used for any other purpose, and this license terminates upon your cessation of use of the Website or Services or at the termination of this Agreement.</p>
                <br/>

                <li>USER CONTENT</li>
                <p><b>Content Responsibility.</b></p>
                <p>The website permits you to share content, post comments, feedback, etc., but you are solely responsible for the content posted by you. You represent that you have required permission to use the content.</p>
                <p>When posting content to the website, please do not post content that:</p>
                <ul>
                    <li>
                    Contains ill-mannered, profane, abusive, racist, or hateful language or expressions, text, photographs, or illustrations that are pornographic or in poor taste, inflammatory attacks of a personal, racial, or religious nature.
                    </li>
                    <li>
                    It is defamatory, threatening, disparaging, grossly inflammatory, false, misleading, fraudulent, inaccurate, unfair, contains exaggeration, or unsubstantiated claims.
                    </li>
                    <li>
                    It violates the privacy rights of any third party, is unreasonably harmful or offensive to any individual or community.
                    </li>
                    <li>
                    Discriminates on the grounds of race, religion, national origin, gender, age, marital status, sexual orientation, or disability, or refers to such matters in any manner prohibited by law.
                    </li>
                    <li>
                    Violates or inappropriately encourages the violation of any municipal, state, federal, or international law, rule, regulation, or ordinance.
                    </li>
                    <li>
                    Uses or attempts to use another's account, password, service, or system except as expressly permitted by the Terms of use uploads or transmits viruses or other harmful, disruptive, or destructive files. 
                    </li>
                    <li>
                    Sends repeated messages related to another user and/or makes derogatory or offensive comments about another individual or repeats prior posting of the same message under multiple emails or subjects.
                    </li>
                    <li>
                    Any submitted content that includes, but is not limited to the following, will be refused. If repeated violations occur, we reserve the right to cancel user access to the website without advanced notice.
                    </li>
                </ul>
                <br/>

                <li>INTELLECTUAL PROPERTY</li>
                <p>
                You agree that the Website and all Services provided by us are the property of allcitycleaning.com, including all copyrights, trademarks, trade secrets, patents, and other intellectual property ("Our IP"). You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing purpose. You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks, or Uniform Resource Locators (URLs), without express written permission from us.
                </p>
                <ul>
                    <li>
                    To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and make derivative works of any content you publish, upload, or otherwise make available to the Website ("Your Content"). We claim no further proprietary rights in your Content.
                    </li>
                    <li>
                    If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please contact us and let us know.
                    </li>
                </ul>
                <br/>

                <li>USER OBLIGATIONS</li>
                <p>As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another term, as well as a password. You may also provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this information. This identifying information will enable you to use the Website and Services. You must not share such identifying information with any third party, and if you discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for maintaining the safety and security of your identifying information, as well as keeping us apprised of any changes to your identifying information. Providing false or inaccurate information or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.</p>
                <br/>

                <li>ACCEPTABLE USE</li>
                <p>You agree not to use the Website or Services for any unlawful purpose, or any purpose prohibited under this clause. You agree not to use the Website or Services in any way that could damage the Website, Services, or general business of allcitycleaning.co.uk.</p>
                <ul>
                    <li>You further agree not to use the Website or Services:</li>
                    <li>To harass, abuse, or threaten others or otherwise violate any person's legal rights.</li>
                    <li>To violate any of our intellectual property rights or any third party.</li>
                    <li>To upload or otherwise disseminate any computer viruses or other software that may damage the property of another.</li>
                    <li>To perpetrate any fraud.</li>
                    <li>To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme.</li>
                    <li>To publish or distribute any obscene or defamatory material.</li>
                    <li>To publish or distribute any material that incites violence, hate, or discrimination towards any group.</li>
                    <li>To unlawfully gather information about others.</li>
                </ul>
                <br/>

                <li>INDEMNIFICATION</li>
                <p>You agree to defend and indemnify us and any of our affiliates (if applicable) and hold us harmless against any legal claims and demands, including reasonable attorney's fees, which may arise from or relate to your use or misuse of the Website or Services, your breach of this Agreement, or your conduct or actions. You agree that we shall be able to select its legal counsel and may participate in its defense if we wish.</p>
                <br/>

                <li>LIABILITY</li>
                <ul>
                    <li>The Company will not be liable under any circumstances for any loss, expenses, damages, delays, costs, or compensation (whether direct, indirect, or consequential) which may be suffered or incurred by the Customer arising from or in any way connected with:</li>
                    <li>Its failure to provide its services because of factors that are beyond its control. Factors beyond its control include acts of floods, severe weather conditions, and inability to gain access to premises, lack of appropriate resources, such as water, electricity, and lighting.</li>
                    <li>Late arrival of the Company's cleaners at the service address. The Company endeavors to be right on time on any visit, but sometimes due to transport-related and other problems which are beyond the Company's control, the Company's cleaners may arrive with a delay, or the cleaning visit may be re-scheduled.</li>
                    <li>Existing damage to Customer's property in the form of old stains/burns/spillages etc. which can't be cleaned/removed completely by the cleaners using the industry standard cleaning methods.</li>
                    <li>The unsatisfactory result from the cleaning services due to the Customer or third-party walking on wet floors or using appliances during or shortly after the cleaning process.</li>
                    <li>The Company will not be liable for any odors arising during and/or after cleaning service when this is due to factors such as lack of ventilation and/or appropriate heating.</li>
                    <li>The Company will not be liable under any circumstances for any loss, expenses, damages, delays, costs, or compensation (whether direct, indirect, or consequential) which may be suffered or incurred by the Customer if the Customer has an outstanding balance aged 14 days or more from the date the payment was due.</li>
                </ul>
            </ol>


            <p id="tacsec12" className='display-6 pt-4'><i><b>MODIFICATION & VARIATION</b></i></p>
            <p>We may, from time to time and at any time without notice to you, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or variations will replace any prior version of this Agreement unless prior versions are specifically referred to or incorporated into the latest modification or variation of this Agreement.</p>


            <p id="tacsec13" className='display-6 pt-4'><i><b>ENTIRE AGREEMENT</b></i></p>
            <p>This Agreement constitutes the entire understanding between the Parties concerning any use of this Website. This Agreement supersedes and replaces all prior or contemporaneous agreements or understandings, written or oral, regarding the use of this Website.</p>


            <p id="tacsec14" className='display-6 pt-4'><i><b>SERVICE INTERRUPTIONS</b></i></p>
            <p>We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the Website may be affected by unanticipated or unscheduled downtime, for any reason, but that we shall have no liability for any damage or loss caused because of such downtime.</p>


            <p id="tacsec15" className='display-6 pt-4'><i><b>TERM, TERMINATION & SUSPENSION</b></i></p>
            <p>We may terminate this Agreement with you at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if you violate any of the terms outlined herein, including, but not limited to, violating the intellectual property rights of us or a third party, failing to comply with applicable laws or other legal obligations, and/or publishing or distributing illegal material. If you have registered for an account with Us, you may also terminate this Agreement at any time by contacting us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall remain in full force and effect.</p>


            <p id="tacsec16" className='display-6 pt-4'><i><b>NO WARRANTIES</b></i></p>
            <p>You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by us are on an "As Is" basis. We hereby expressly disclaim any express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to you, through your computer system, or because of the loss of your data from your use of the Website or Services is your sole responsibility and that we are not liable for any such damage or loss.</p>


            <p id="tacsec17" className='display-6 pt-4'><i><b>LIMITATION ON LIABILITY</b></i></p>
            <p>We are not liable for any damages that may occur to you because of your use of the Website or Services, to the fullest extent permitted by law. This section applies to any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.</p>


            <p id="tacsec18" className='display-6 pt-4'><i><b>GENERAL PROVISIONS</b></i></p>
            <ol type='1'>
                <li>
                <b>JURISDICTION, VENUE & CHOICE OF LAW</b>: The terms herein will be governed by and construed by the laws of United Kingdom without giving effect to any principles of conflicts of law. The Courts of United Kingdom shall have exclusive jurisdiction over any dispute arising from the use of the Website.
                </li>
                <li>
                <b>ASSIGNMENT</b>: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred in whole or part by you. Should this Agreement, or the rights granted hereunder, be assigned, sold, leased, or otherwise transferred by us, the rights and liabilities of <b>allcitycleaning.co.uk</b> will bind and inure to any assignees, administrators, successors, and executors.
                </li>
                <li>
                <b>SEVERABILITY</b>: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts and sub-parts will be enforced to the maximum extent possible. In such a condition, the remainder of this Agreement shall continue in full force. 
                </li>
                <li>
                <b>NO WAIVER</b>: If we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or any other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.
                </li>
                <li>
                <b>HEADINGS FOR CONVENIENCE ONLY</b>: Headings of parts and sub-parts under this Agreement are for convenience and organization only. Headings shall not affect the meaning of any provisions of this Agreement. 
                </li>
                <li>
                <b>NO AGENCY, PARTNERSHIP, OR JOINT VENTURE</b>: No agency, partnership, or joint venture has been created between the Parties because of this Agreement. No Party has any authority to bind the other to third parties.
                </li>
                <li>
                <b>FORCE MAJEURE</b>: We are not liable for any failure to perform due to causes beyond its reasonable control, including, but not limited to, acts of God, acts of civil authorities, acts of military authorities, riots, embargoes, acts of nature, and natural disasters, and other acts which may be due to unforeseen circumstances, i.e., COVID-19!
                </li>
                <li>
                <b>ELECTRONIC COMMUNICATIONS PERMITTED</b>: Electronic communications are permitted to both Parties under this Agreement, including e-mail. For any questions or concerns, please use the contact us. You may do so via the contact us or email us at <b><u>office@allcitycleaning.co.uk</u></b> or call us at <b>+44 (0) 333 090 14 64</b>.
                </li>
            </ol>
        </section>
    )
}

export default TermsAndConditions;