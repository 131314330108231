import React, { useEffect, useState } from 'react';
import './Admin.css';
import 'bootstrap/dist/css/bootstrap.css';
import $ from 'jquery';
import axios from '../../api/axios';

function Admin({authToken}:any) {
    const services = [
        {value: '£59.00 - Through Lounge', text: 'Trough Lounge'},
        {value: '£42.00 - Living Room', text: 'Living Room'},
        {value: '£42.00 - Dining Room', text: 'Dining Room'},
        {value: '£39.00 - Room', text: 'Room'},
        {value: '£38.00 - Flight of Stairs', text: 'Flight of Stairs'},
        {value: '£10.00 - Landing', text: 'Landing'},
        {value: '£19.00 - Hallway', text: 'Hallway'},
        {value: '£20.00 - Small Rug', text: 'Small Rug'},
        {value: '£29.00 - Medium Rug', text: 'Medium Rug'},
        {value: '£39.00 - Large Rug', text: 'Large Rug'},
        {value: '£30.00 - Kitchen', text: 'Kitchen'},
        {value: '£12.00 - Bathroom', text: 'Bathroom'},
        {value: '£25.00 - Mattress', text: 'Mattress'},
        {value: '£{23/39/38/64} - {Short / Long} {Curtains / Blind}', text: 'Curtains Blinds'},
        {value: '£vary - Sofa NxSeats (MODE)', text: 'Sofa'},
        {value: '£vary - L-shaped Sofa NxSeats (MODE)', text: 'L Sofa'},
        {value: '£{25/30} - Bed Frame', text: 'Bed Frame'},
        {value: '£{25/35} - Headboard', text: 'Headboard'},
        {value: '£{25/30} - Armchair', text: 'Armchair'},
        {value: '£{12/15} - Kitchen Chair', text: 'Kitchen Chair'},
        {value: '£{5/10/10/15} - {Small / Large} Cushion', text: 'Cushion'},
        {value: '£0 - Name', text: 'Custom'}
    ];

    const [selectedService, setService] = useState(services[0].value)
    const [serviceList, setServiceList]:any = useState([])
    const [priceList, setPriceList]:any = useState([])
    const [paymentRequired, setupPayment] = useState(false);
    const [paymentToken, setPaymentToken] = useState('');
    const [congestionCharge, setCongestionCharge] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [cashPayment, setCashPayment] = useState(false);

    const [date, setDate] = useState('');
    const [address, setAdress] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerTel, setCustomerTel] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [finalBookingPrice, setFinalBookingPrice] = useState(0);
    const [cardPaymentStatus, setCardPaymentStatus] = useState('pending');

    const [bookingEditorMode, setBookingEditorMode] = useState(false);
    const [bookingNumber, setBookingNumber] = useState('');
    const [servicesFormat, setServicesFormat] = useState('');
    const [chargesFormat, setChargesFormat] = useState('');
    const [paymentFormat, setPaymentFormat] = useState('');

    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentCode, setPaymentCode] = useState('');

    const [mailTo, setMailTo] = useState('');
    const [mailSubject, setMailSubject] = useState('');
    const [mailBody, setMailBody] = useState('');

    useEffect(() => {
        // Take an action everytime a state has changed (in the dep array)
        async function renderPaymentCode() {
            const serviceAmount = priceList.reduce((sum:number, c:number) => sum = sum + Number(c), 0);
            const finalAmount = (congestionCharge + discount + Number(serviceAmount));
            setFinalBookingPrice(Math.round(finalAmount * 100) / 100);
            
            if(paymentRequired){
                try {
                    let techRef = prompt('Enter technician code:', 'GD,IM,B');
                    if(techRef !== null && techRef !== "" && ['GD', 'IM', 'B'].includes(techRef)) {
                        const targetTextField = document.getElementById('payment-code-f');
                        const serviceFee = Math.round((finalAmount * 0.034) * 100) / 100;
                        const sum = Math.round((finalAmount + serviceFee) * 100) / 100;
                        
                        const response:any = await axios.post(
                            '/pay/create/' + (sum*100),
                            {
                                techRef,
                                token: authToken
                            }
                        );
                        targetTextField!.innerText = 'Payment code: ' + response?.data;
                        document.getElementById('payment-amount-f')!.innerText = String(`Payment amount: £${finalAmount} (£${serviceFee})`); 
                        setPaymentToken(response?.data);
                    } else {
                        alert('Invalid!');
                    }
                } catch (error) {
                    console.log('usehook: err');
                    console.log(error)
                }
            }
        }
        renderPaymentCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentRequired])

    const handleChange = (value: any) => {
        setService(value);
        const dataFragment = value.split(' - ');
        $('#b-item-price').val(dataFragment[0].substring(1));
        $('#b-item-name').val(dataFragment[1]);
    }

    const handleAddItem = (e:any) => {
        e.preventDefault();
        const item = $('#b-item-name').val();
        const price = $('#b-item-price').val();
        setServiceList([...serviceList, item])
        setPriceList([...priceList, price])
    }

    const handleRemoveItem = (e:any) => {
        e.preventDefault();
        const indexToRemove = e.currentTarget.className.split(' ')[1];
        const newServiceList = [...serviceList];
        newServiceList.splice(indexToRemove, 1);
        setServiceList(newServiceList);
        const newPriceList = [...priceList];
        newPriceList.splice(indexToRemove, 1);
        setPriceList(newPriceList);
    }

    const handleGeneratePaymentCode = async (e:any) => {
        e.preventDefault();
        setupPayment(current => !current);  
    }

    const handleCheckPaymentCode = async (e:any) => {
        e.preventDefault();
        const response = await axios.post(
            '/pay/check/',
            {
                code: paymentToken,
                token: authToken
            }
        );
        document.getElementById('payment-status-s')!.innerText = 'Status: ' + response?.data; 
        setCardPaymentStatus(response?.data); 
    }

    const handleCongestionCharge = (e:any) => {
        if(e.target.checked) {
            setCongestionCharge(15);
        } else {
            setCongestionCharge(0);
        }
    }

    const handleDiscount = (e:any) => {
        const discountPercentage = Number($('#discount-perc').val()) / 100;
        const discount = -discountPercentage * Number(priceList.reduce((sum:number, c:number) => sum = sum + Number(c), 0))
        if(e.target.checked) {
            setDiscount(discount);
        } else {
            setDiscount(0);
        }
    }

    const handleCashPayment = (e:any) => {
        e.preventDefault();
        setCashPayment(current => !current);
    }

    const handleBookingComplete = async (e:any) => {
        e.preventDefault();
        if(paymentRequired || cashPayment) {
            const bDate = date.split('T')[0]; // YYYY-MM-DD
            const bTime = date.split('T')[1]; // HH:MM
            const bAddress = address; // A,B,C
            const bName = customerName; // Text
            const bTel = customerTel; // 077...
            const bEmail = customerEmail; // user@domain.com
            const bServices = `${serviceList.join(',')}***${priceList.join(',')}`; // A,B***1.00,2.00
            var bCharges = ``; // 104.84*100.00*15.00*2.17*-12.33
            var bPayment = ``; // CASH,PENDING

            const serviceAmount = priceList.reduce((sum:number, c:number) => sum = sum + Number(c), 0);
            if(paymentRequired) {
                // Card payment
                const serviceFee = 0.034 * finalBookingPrice;
                bCharges = `${Math.round((finalBookingPrice + serviceFee) * 100) / 100}*${Math.round(serviceAmount * 100) / 100}*${Math.round(congestionCharge * 100) / 100}*${Math.round(serviceFee * 100) / 100}*${Math.round(discount * 100) / 100}`;
                bPayment = `Card,${cardPaymentStatus},${paymentToken}`;
            }
            if(cashPayment){
                // Cash payment
                bCharges = `${Math.round((finalBookingPrice) * 100) / 100}*${Math.round(serviceAmount * 100) / 100}*${Math.round(congestionCharge * 100) / 100}*0.00*${Math.round(discount * 100) / 100}`;
                bPayment = `Cash,pending`;
            }

            const response = await axios.post('/booking/create', {
                time: bTime,
                date: bDate,
                address: bAddress,
                name: bName,
                tel: bTel,
                email: bEmail,
                services: bServices,
                charges: bCharges,
                payment: bPayment,
                token: authToken
            })
            
            if (response.status === 200) {alert('Completed!')};
        } else {
            alert('Enter a mode of payment!');
        }
    }

    const handleBookingSearch = async (e:any) => {
        e.preventDefault();

        const response = await axios.post(
            `/booking/search/${bookingNumber}`,
            {
                token: authToken
            }
        );
        if(response.data.statusCode === 200) {
            const o = response.data.data;
            setDate(o.date.split(':')[0] + ':' + o.date.split(':')[1]);
            setAdress(o.address);
            setCustomerName(o.name);
            setCustomerTel(o.tel);
            setCustomerEmail(o.email);
            setServicesFormat(o.services);
            setChargesFormat(o.charges);
            setPaymentFormat(o.payment);
        } else {
            alert('Booking not found!');
        }
    }

    const handleEditorSwitch = (e:any) => {
        e.preventDefault();
        setBookingEditorMode(current => !current);
    }

    const handleBookingAlternation = async (e:any) => {
        e.preventDefault();

        if(bookingEditorMode) {
            const bDate = date.split('T')[0]; // YYYY-MM-DD
            const bTime = date.split('T')[1]; // HH:MM

            const response = await axios.post('/booking/edit', {
                id: bookingNumber,
                time: bTime,
                date: bDate,
                address,
                name: customerName,
                tel: customerTel,
                email: customerEmail,
                services: servicesFormat,
                charges: chargesFormat,
                payment: paymentFormat,
                token: authToken
            })
            
            if(response.status === 200) {
                alert(`Booking [${bookingNumber}] has been updated!`);
            }
        } else {
            alert('Activate editor mode!');
        }
    }

    const generatePaymentCode = async (e:any) => {
        e.preventDefault();

        let techRef = prompt('Enter technician code:', 'GD,IM,B');
        if(techRef !== null && techRef !== "" && ['GD', 'IM', 'B'].includes(techRef)) {
            const response = await axios.post(
                `/pay/create/${paymentAmount}`,
                {
                    techRef,
                    token: authToken
                }  
            );
            setPaymentCode(response?.data);
        }
    }

    const getPaymentTokenStatus = async (e:any) => {
        e.preventDefault();

        const response = await axios.post(
            `/pay/summary`,
            {
                token: authToken
            }
        );
        document.getElementById('payment-tbody')!.innerHTML = response.data;
    }

    const erasePaymentTokenStatus = (e:any) => {
        e.preventDefault();
        document.getElementById('payment-tbody')!.innerHTML = '';
    }

    const sendEmail = (e:any) => {
        e.preventDefault();
        axios.put('/mail/new', {
            to: mailTo,
            subject: mailSubject,
            body: mailBody,
            token: authToken
        });
        alert('Sent!');
    }

    return(
        <section className='mx-4 my-3'>
            <h3 className='display-3'>Booking</h3>
            <form className='d-flex flex-column align-items-start mb-3'>
                <label htmlFor="services">Services</label>
                <select value={selectedService} onChange={e => handleChange(e.target.value)} >
                    {services.map(services => (
                        <option key={services.value} value={services.value}>
                            {services.text}
                        </option>
                    ))}
                </select>
                <section className='d-flex flex-column align-items-start'>
                    <input type="text" id='b-item-price' className='b-input'/>
                    <input type="text" id='b-item-name' className='b-input'/>
                    <button className='b-button' onClick={handleAddItem}>Add item</button>
                    {selectedService.includes('Sofa') ? (
                    <table>
                        <tbody>
                        <tr><th>Sofa Grid</th><th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>More</th></tr>
                        <tr><td>Normal HWE</td><td>25</td><td>50</td><td>70</td><td>90</td><td>110</td><td>+20</td></tr>
                        <tr><td>Normal Dry/Leather</td><td>30</td><td>60</td><td>85</td><td>110</td><td>135</td><td>+25</td></tr>
                        <tr><td>L-Sh HWE</td><td>-</td><td>-</td><td>75</td><td>100</td><td>125</td><td>+25</td></tr>
                        <tr><td>L-sh Dry/Leather</td><td>-</td><td>-</td><td>90</td><td>125</td><td>150</td><td>+25</td></tr>
                        </tbody>
                    </table>
                    ) : (<></>)}
                </section>
                {serviceList.length > 0 ? (
                <>
                <section className='b-view-list'>
                    {serviceList.map((item:string,index:number) => 
                        <div key={index} className={'d-flex flex-row'}>
                            <button className={'b-button-2 ' + index}  onClick={handleRemoveItem}> X </button>
                            <span className='px-2'> £{priceList[index]} </span>
                            <span className='px-2'> {item} </span>
                        </div>
                    )}
                    <span>Total price: {priceList.reduce((sum:number, c:number) => sum = sum + Number(c), 0)}</span>
                </section>
                <section className='d-flex flex-column'>
                    <input 
                        type="text" 
                        placeholder='Prefix, First line, POSTCODE' 
                        className='b-input'
                        value={address}
                        onChange={(e) => setAdress(e.target.value)}
                    />
                    <input 
                        type="text" 
                        placeholder='Name Surname' 
                        className='b-input'
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <input 
                        type="email" 
                        placeholder='user@email.com' 
                        className='b-input'
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                    />
                    <input 
                        type="tel" 
                        placeholder='0333 090 1464' 
                        className='b-input'
                        value={customerTel}
                        onChange={(e) => setCustomerTel(e.target.value)}
                    />
                    <input 
                        type="datetime-local" 
                        name="timeslot"
                        className='b-input'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <label>
                        <input 
                            type="checkbox" 
                            name="cgch" 
                            id="cgch" 
                            className='b-button-3' 
                            onChange={handleCongestionCharge}
                        />
                        (15) Congestion charge 
                        <a 
                            href='https://tfl.gov.uk/modes/driving/congestion-charge/congestion-charge-zone' 
                            target='_blank' 
                            rel='noreferrer'>
                            link
                        </a>
                    </label>
                    <div>
                        <label>
                            <input 
                                type="checkbox" 
                                placeholder='Discount' 
                                id='discount' 
                                className='b-button-3' 
                                onChange={handleDiscount}
                            />
                            Discount
                        </label>
                        <input 
                            type="number" 
                            id="discount-perc" 
                            className='b-button-4' 
                            min={5} max={100} step={5}
                        />
                    </div>
                    <button className='b-button-2' onClick={handleGeneratePaymentCode}>Card payment</button>
                    
                    {paymentRequired ? (
                        <div>
                            <p id='payment-code-f'>Payment code: UNSET</p>
                            <p id='payment-amount-f'>Payment amount: ?</p>
                            <p id='payment-status-s'>Status: NOT PAID</p>
                            <button onClick={handleCheckPaymentCode}>Check</button>
                        </div>
                    ) : (<></>)}
                    <button className={cashPayment ? 'b-button-2 bg-success text-white' : 'b-button-2 bg-danger text-white'} onClick={handleCashPayment}>Pay by CASH</button>
                </section>
                <button className='bg-secondary my-2 text-white' onClick={handleBookingComplete}>Complete booking</button>
                </>
                ) : (<p>No items added!</p>)}
            </form>

            <h3 className='display-3'>Pull data</h3>
            <form className='d-flex flex-column align-items-start mb-3'>
                <div>
                    <input 
                        type="text" 
                        placeholder='Booking #' 
                        className='b-input' 
                        value={bookingNumber} 
                        onChange={e => setBookingNumber(e.target.value)}
                    />
                    <button className='mx-2 px-2 b-button-2' onClick={handleBookingSearch}>Find</button>
                </div>
                <div>
                    <input 
                        type="datetime-local"
                        className='b-input'
                        readOnly={bookingEditorMode ? false : true}
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                    <button className={bookingEditorMode ? 'mx-2 px-2 b-button-2 bg-success' : 'mx-2 px-2 b-button-2 bg-danger'} onClick={handleEditorSwitch}>Editor mode</button>
                </div>
                <div>
                    <input 
                        type="text"
                        placeholder='Address' 
                        className='b-input'
                        readOnly={bookingEditorMode ? false : true}
                        value={address}
                        onChange={(e) => setAdress(e.target.value)}
                    />
                    <button className='mx-2 px-2 b-button-2 bg-secondary' onClick={handleBookingAlternation}>Change booking</button>
                </div>
                <input 
                    type="text"
                    placeholder='Name' 
                    className='b-input'
                    readOnly={bookingEditorMode ? false : true}
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                />
                <input 
                    type="text"
                    placeholder='Tel'
                    className='b-input'
                    readOnly={bookingEditorMode ? false : true}
                    value={customerTel}
                    onChange={(e) => setCustomerTel(e.target.value)}
                />
                <input 
                    type="text"
                    placeholder='Email'
                    className='b-input' 
                    readOnly={bookingEditorMode ? false : true}
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                />
                <label><i>Syntax:</i> Service,Service***00.00,00.00</label>
                <textarea
                    placeholder='Services' 
                    readOnly={bookingEditorMode ? false : true}
                    value={servicesFormat}
                    onChange={(e) => setServicesFormat(e.target.value)}
                />
                <label><i>Syntax:</i> Total*Services*Congestion*Fee*Discount</label>
                <input 
                    type="text"
                    placeholder='Charges' 
                    className='b-input'
                    readOnly={bookingEditorMode ? false : true}
                    value={chargesFormat}
                    onChange={(e) => setChargesFormat(e.target.value)}
                />
                <label><i>Syntax:</i> Card,Status,Code OR Cash,Status</label>
                <input 
                    type="text"
                    placeholder='Payment' 
                    className='b-input'
                    readOnly={bookingEditorMode ? false : true}
                    value={paymentFormat}
                    onChange={(e) => setPaymentFormat(e.target.value)}
                />
            </form>
            
            <h3 className='display-3'>Payment codes</h3>
            <form className='d-flex flex-column align-items-start mb-3'>
                <div>
                    <input 
                        type="text" 
                        placeholder='1055'
                        className='b-input'
                        value={paymentAmount}
                        onChange={(e) => setPaymentAmount(e.target.value)}
                    />
                    <button className='mx-2 px-2 b-button-2' onClick={generatePaymentCode}>Go</button>
                </div>
                <input 
                    type="text"
                    placeholder='ABCD1234'
                    className='b-input'
                    value={paymentCode}
                    onChange={(e) => setPaymentCode(e.target.value)} 
                    readOnly={true}
                />
                <div>
                    <button className='b-button-2 bg-primary' onClick={getPaymentTokenStatus}>Refresh</button>
                    <button className='b-button-2 bg-danger' onClick={erasePaymentTokenStatus}>Hide</button>
                </div>
                <table><tbody id='payment-tbody'></tbody></table>
            </form>

            <h3 className='display-3'>Email</h3>
            <form className='d-flex flex-column align-items-start mb-3 admin-mail'>
                <input 
                    type="text"
                    placeholder='user@domain.com'
                    className='b-input'
                    value={mailTo}
                    onChange={(e) => setMailTo(e.target.value)}  
                />
                <input 
                    type="text"
                    placeholder='Re: Quote'
                    className='b-input'
                    value={mailSubject}
                    onChange={(e) => setMailSubject(e.target.value)}
                />
                <div className='d-flex flex-column my-2'>
                    <span>~s to open 'p' tag.</span>
                    <span>~e to close 'p' tag.</span>
                    <span>~br to add newline.</span>
                </div>
                <textarea 
                    placeholder='~s This is new paragraph ~e ~br'
                    value={mailBody}
                    onChange={(e) => setMailBody(e.target.value)} 
                />
                <button className='my-3 b-button-2 bg-secondary' onClick={sendEmail}>Send</button>
            </form>
        </section>
    )
}

export default Admin;