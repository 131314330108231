import React, { useState } from 'react';
import './Footer.css'
import axios from '../../api/axios';

function Footer() {
    const [subsEmail, setSubsEmail] = useState('');

    const handleSubscription = async (e:any) => {
        e.preventDefault();
    
        if(subsEmail.trim().length > 0) {
            const response = await axios.put('/mail/subscribe',{
                email: subsEmail
            });

            if(response?.status === 200) {
                createSubmitLead();
                alert('You have subscribed successfully!');
            }
        } else {
            alert('Please enter your email first.');
        }
    }

    const createSubmitLead = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = "fbq('track', 'SubmitApplication');";
        document.head.appendChild(script);
    }

    return(
        <footer className='px-4 py-5 d-flex justify-content-center'>
            <div>
                <span className='act-cr'>AllCityCleaning ©2020-2023. ALL RIGHTS RESERVED.</span>

                <div className='d-flex flex-column footer-sp footer-c pb-4'>
                    <span className='footer-label pb-3'>Legal</span>
                    <div className='d-flex flex-column footer-c'>
                        <a href='/privacy-policy' className='href-no-effect' target={'_blank'} rel='noreferrer'>Privacy Policy</a>
                        <a href='/cookie-policy' className='href-no-effect' target={'_blank'} rel='noreferrer'>Cookies</a>
                        <a href='/terms-and-conditions' className='href-no-effect' target={'_blank'} rel='noreferrer'>Terms and Conditions</a>
                        <a href='/user-agreement' className='href-no-effect' target={'_blank'} rel='noreferrer'>User Agreement</a>
                        <a href='/disclaimer' className='href-no-effect' target={'_blank'} rel='noreferrer'>Disclamer</a>
                    </div>
                    <div className='footer-c'>
                        <a href='https://www.facebook.com/100087343585959/' target={'_blank'} rel='noreferrer'>
                            <svg height="100%" version="1.1" viewBox="0 0 512 512" width="100%" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z"/>
                            </svg>
                        </a>
                        <a href='https://www.linkedin.com/company/79456273/' target={'_blank'} rel='noreferrer'>
                            <svg height="100%" version="1.1" viewBox="0 0 512 512" width="100%" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
                                <path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-386.892,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Zm-288.985,423.278l0,-225.717l-75.04,0l0,225.717l75.04,0Zm270.539,0l0,-129.439c0,-69.333 -37.018,-101.586 -86.381,-101.586c-39.804,0 -57.634,21.891 -67.617,37.266l0,-31.958l-75.021,0c0.995,21.181 0,225.717 0,225.717l75.02,0l0,-126.056c0,-6.748 0.486,-13.492 2.474,-18.315c5.414,-13.475 17.767,-27.434 38.494,-27.434c27.135,0 38.007,20.707 38.007,51.037l0,120.768l75.024,0Zm-307.552,-334.556c-25.674,0 -42.448,16.879 -42.448,39.002c0,21.658 16.264,39.002 41.455,39.002l0.484,0c26.165,0 42.452,-17.344 42.452,-39.002c-0.485,-22.092 -16.241,-38.954 -41.943,-39.002Z"/>
                            </svg>
                        </a>
                    </div>
                </div>

                <div className='footer-subscribe-charge footer-c'>
                    <div className='d-flex flex-column footer-sp footer-c pb-4'>
                        <span className='footer-label pb-3'>Subscribe</span>
                        <span className="pb-2 w-270">
                            Sign up to our mailing list for exclusive 10% off your next booking.
                        </span>
                        <div className='d-flex flex-row'>
                            <input 
                                type='email' 
                                placeholder='Enter your email'
                                value={subsEmail}
                                onChange={(e) => setSubsEmail(e.target.value)} />
                            <button className='o-ml-3' onClick={handleSubscription}>Subscribe</button>
                        </div>
                    </div>

                    <div className='d-flex flex-column footer-sp pb-4'>
                        <span className='footer-label pb-3'>Pay a charge</span>
                        <div>
                            <a href='/pay' className='href-no-effect'>
                                <button>Payment Portal</button>
                            </a>
                        </div>
                    </div>

                    <div className='no-cr'>
                        <span>AllCityCleaning ©2020-2023. ALL RIGHTS RESERVED.</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;