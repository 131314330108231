import './ReadyToBook.css'

function ReadyToBook({props}: any) {
    return(
        <section className={props[0] ? 'quick-actions-parent COLOR-SD align-items-center py-4' : 'quick-actions-parent COLOR-SD-ALT align-items-center py-4'}>
            <div className='quick-actions-small-ex'>
                <span className={props[0] ? 'quick-actions-label' : 'quick-actions-label COLOR-SD-B'}>Ready to book with us?</span>
            </div>
            <div>
                <a href={props[1]} className='no-href-effect'>
                    <button className='quick-actions-button'>Get My Quick Quote</button>
                </a>
            </div>
        </section>
    )
}

export default ReadyToBook;