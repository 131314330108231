import './ServicesSummary.css';

const service = (title: string, beforeRef: string, afterRef: string, subText: string) => {
    return (
        <div className='service-box-parent'>
            <span className='py-2 service-box-title COLOR-JA12'>{title}</span>
            <div className='service-box-subparent COLOR-JJ31'>
                <div>
                    <img src={`img/${beforeRef}`} alt={`${title}: Before cleaning`} className='services-mini-img' /> 
                </div>
                <div>
                    <img src={`img/${afterRef}`} alt={`${title}: After cleaning`} className='services-mini-img' /> 
                </div>
            </div>
            <div className='service-box-subtext COLOR-JK13 py-2'>
                <span>{subText}</span>
            </div>
        </div>
    )
}

function ServicesSummary() {
    return(
        <section className="px-4 py-5">
            <div className='display-4 pb-4 text-center py-4'><span className='COLOR-J1'>What Our Cleaning Experts Can Do For You</span></div>
            <div className='services-summary-parent pt-4 pb-5'>
                {service('Carpet Cleaning',
                'carpet-cleaning-before.jpg', 
                'carpet-cleaning-after.jpg', 
                'A deep-clean of your carpet to ensure a healthier home environment.')}
                {service('Upholstery Cleaning',
                'upholstery-before.jpg',
                'upholstery-after.jpg',
                'Sofas, curtains, armchairs, mattresses... You name it. We clean it.')}
            </div>
        </section>
    )
}

export default ServicesSummary;