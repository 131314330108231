import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import './QuoteForm.css';
import $ from 'jquery';

declare var gtag_report_conversion: any;

function QuoteForm ({expanded}: any) {
    useEffect(() => {
      const createLead = () => {
        const script = document.createElement('script');
        script.text = "fbq('track','Lead')";
        document.head.appendChild(script);
      }

      const createCaptchaOnloadScript = () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = `
            <!-- ReCapcha Code -->

            const recaptchaExpired = () => {
                localStorage.setItem('captcha','');
            }

            const handleCaptcha = (event) => {
                localStorage.setItem('captcha',event);
            }

            onloadCallback = function() {
                grecaptcha.render('form-recaptcha', {
                    'sitekey' : '6Lc3e50jAAAAAPuD8sZ37d6Y4lgFyJiDAmxAxW6-',
                    'callback' : handleCaptcha,
                    'expired-callback' : recaptchaExpired
                });
            };
        `;
        document.head.appendChild(script);
      }

      const createCaptchaScript = () => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
      }

      createLead();
      createCaptchaOnloadScript();
      createCaptchaScript();
    }, []);
    

    const [quoteInfo, setQuoteInfo] = useState({
        name: "",
        tel: "",
        email: "",
        msg: ""
    });


    const handleQuoteSubmit = async (e: any) => {
        e.preventDefault();

        // Start animation
        $('#q-text-span').text('');
        $('#q-loader').removeClass('HIDE');

        // Check captcha verification
        var captcha;
        if (localStorage.getItem('captcha')) {
            captcha = localStorage.getItem('captcha');
        } else {
            captcha = '';
        }

        // Check fields
        if(quoteInfo.name.trim().length !== 0 &&
            quoteInfo.tel.trim().length !== 0 &&
            quoteInfo.email.trim().length !== 0 &&
            quoteInfo.msg.trim().length !== 0) 
        {
            if(captcha?.length !== 0) {
                const response = await axios.put('/mail/quote', {
                    name: quoteInfo.name,
                    tel: quoteInfo.tel,
                    email: quoteInfo.email,
                    msg: quoteInfo.msg,
                    captcha
                });

                if(response?.status === 200) {
                    gtag_report_conversion();
                    window.location.href = '/quote/thanks';
                } else {
                    createErrorMessage('ReCaptcha verification failed. Try again.');
                }
            } else {
                createErrorMessage('Please complete the ReCAPTCHA verification');
            }
        } else {
            createErrorMessage('Please fill in all information.');
        }

        // Stop animation
        setTimeout(() => {
            $('#q-text-span').text('Get My Quick Quote');
            $('#q-loader').addClass('HIDE');
        }, 330);
    }

    const createSuccessMessage = (msg: string) => {
        $('#quote-alert')[0].style.display = 'block';
        $('#quote-alert div').addClass('alert-success animate__fadeIn');
        $('#quote-alert div').text(msg);
        setTimeout(() => {
            $('#quote-alert div').removeClass('alert-success animate__fadeIn');
            $('#quote-alert')[0].style.display = 'none';
        }, 5500);
    }

    const createErrorMessage = (msg: string) => {
        $('#quote-alert')[0].style.display = 'block';
        $('#quote-alert div').addClass('alert-danger animate__shakeX');
        $('#quote-alert div').text(msg);
        setTimeout(() => {
            $('#quote-alert div').removeClass('alert-danger animate__shakeX');
            $('#quote-alert')[0].style.display = 'none';
        }, 4250);
    }

    return(
        <section id='qf04' className={!expanded ? 'COLOR-DA01 d-flex flex-column align-items-center' : 'COLOR-DA01 d-flex flex-column align-items-center vh-100'}>
            <div className='d-flex flex-column align-items-center quote-parent m-4'>
                <h3 className='COLOR-DD1 py-3 display-5 text-center'>Get a <span className='b600'>no obligation quote</span>, we'll reply in <span className='b600'>less than 30 minutes</span>. <span className='b600'>Guaranteed!</span></h3>
                
                <div className='quote-field'>
                    <input type="text" name="qname" placeholder=' ' className='COLOR-DB00 COLOR-DB01 COLOR-DB02 GLOBAL-AF043 quote-input' value={quoteInfo.name} onChange={(e) => setQuoteInfo({...quoteInfo, name: e.target.value})}/>
                    <label className='quote-label COLOR-DB10'>Name*</label>
                </div>

                <div className='quote-field'>
                    <input type="tel" name="qtel" placeholder=' ' className='COLOR-DB00 COLOR-DB01 COLOR-DB02 GLOBAL-AF043 quote-input' value={quoteInfo.tel} onChange={(e) => setQuoteInfo({...quoteInfo, tel: e.target.value})}/>
                    <label className='quote-label COLOR-DB10'>Phone number*</label>
                </div>

                <div className='quote-field'>
                    <input type="email" name="qmail" placeholder=' ' className='COLOR-DB00 COLOR-DB01 COLOR-DB02 GLOBAL-AF043  quote-input' value={quoteInfo.email} onChange={(e) => setQuoteInfo({...quoteInfo, email: e.target.value})}/>
                    <label className='quote-label COLOR-DB10'>Email*</label>
                </div>

                <div className='quote-field'>
                    <textarea placeholder=' ' className='COLOR-DB00 COLOR-DB01 COLOR-DB02 GLOBAL-AF043 quote-textarea' value={quoteInfo.msg} onChange={(e) => setQuoteInfo({...quoteInfo, msg: e.target.value})}></textarea>
                    <label className='quote-label-tb COLOR-DB10'></label>
                </div>

                <div id='form-recaptcha' className='my-3'></div>

                <div className='quote-rel'>
                    <button className='quote-button' onClick={handleQuoteSubmit}>
                        <span id='q-text-span'>Get My Quick Quote</span>
                        <i id='q-loader' className='fa fa-circle-o-notch fa-spin HIDE'></i>
                    </button>
                    <img src="/img/pointer.png" alt="Pointing arrow" />
                </div>

                
            </div>
            <div id='quote-alert'>
                <div className="alert m-3 animate__animated animate__delay-0s animate__faster" role="alert">This is test alert</div>
            </div>
        </section>
    )
}

export default QuoteForm;