import React from 'react';
import {useState} from 'react';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import './Nav.css';

const label = (name: string, href: string, separator: boolean) => {
    return (
        <a href={href} className={separator ? 'href-no-effect COLOR-A002 COLOR-A004 mwc-1' : 'href-no-effect COLOR-A002 COLOR-A004 mwc-2'}>{name}</a>    
    );
}

function Nav() {
    const [navTrigger, setNavTrigger] = useState(false);

    const handleNavClick = () => {
        setNavTrigger(current => !current);
        $('.nav-holder').slideToggle(700, 'swing')
    };

    return (
        <>
            <nav className="clr-primary nav-mobile-holder COLOR-A001">
                <div className={navTrigger ? "menu-toggle-btn open COLOR-A003" : "menu-toggle-btn COLOR-A003"} onClick={handleNavClick}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className='d-flex align-items-center'>
                    <div className='phone-number-nav mx-3'>
                        {label('0333 090 14 64','tel:03330901464',false)}
                    </div>
                    <a href='/' className='excl-a'>
                        <img src="/img/favicon.png" alt="Logo"/>
                    </a>
                </div>
            </nav>
            <nav className="clr-primary nav-holder COLOR-A001 px-3">
                <div className="a-spaced">
                    <a href='/' className='excl-a'>
                        <img src='/img/logo.png' alt='Logo'/>
                    </a>
                </div>
                <div className="a-spaced d-flex justify-content-between w-100 h-100">
                    <div className='h-100 d-flex align-items-end div-expanded'>
                        {label('home','/',true)}
                        {label('services & faq','/services-faq',true)}
                        {label('contact us','/quote',false)}
                    </div>
                    <div className='phone-number-nav d-flex align-items-end excluded'>
                        {label('0333 090 14 64','tel:03330901464',false)}
                    </div>
                </div>
            </nav>
        </>
        
    );
}

export default Nav;
