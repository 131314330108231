import './Reviews.css'

const stars = (amount: number) => {
    return (
        [...Array(amount)].map((e, i) =>
            <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" key={i}>
                <path d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z" fill="#F1C644"/>
                <path d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z" stroke="#F1C644"/>
            </svg>        
        )
    )
}

const ratingLabel = (name: string, starNumber: number) => {
    const imageRef:string = `https://ui-avatars.com/api/?background=F1C644&color=fff&size=20&rounded=true&bold=true&name=${name}`;
    return (
        <div>
            <div className='reviews-content-rating-sub1'>
                <img src={imageRef} alt='Avatar with initials' />
            </div>
            <div className='reviews-content-rating-sub2'>
                {stars(starNumber)}
            </div>
        </div>
    )
}

const review = (name: string, text: string, rating: number, ratingTop: boolean, order: number, backgroundBlue: boolean) => {
    return (
        <div className={backgroundBlue ? `review-d${order}` : `review-d${order} COLOR-BM-IM`}>
            <div className='reviews-content-title'><span>{name}</span></div>
            {ratingTop ? <div className='reviews-content-rating-parent'>{ratingLabel(name, rating)}</div> : null}
            <div className='reviews-content-text v-scroll-x'>{text}</div>
            {!ratingTop ? <div className='reviews-content-rating-parent'>{ratingLabel(name, rating)}</div> : null}
        </div>
    )
}

function Reviews({props}: any) {
    return (
        <section className={props[0] ? 'px-4 py-5 COLOR-BM' : 'px-4 py-5 COLOR-BM-ALT'}>
            <div className='display-4 py-5 pb-4 reviews-title'>
                <span className={props[0] ? 'COLOR-BC22' : 'COLOR-BC22-ALT'}>"Every single time he gets out stains I think are impossible to remove!"</span>
            </div>

            <div className={props[0] ? 'reviews-subtitle COLOR-BBC12 pb-5' : 'reviews-subtitle COLOR-BBC12-ALT pb-5'}>
                <span>We're Trusted By Thousands Of Homes & Businesses!</span>
            </div>

            <div className='reviews-content py-5'>
                {review('Marina Shtereva', 'This is the first time I am using this cleaning service as I got my carpet cleaned and I must say I was amazed with how fast and friendly the staff were. The after results were also amazing. I would definitely recommend this cleaning service for anyone looking for a professional and quick service.', 5, false, 1, props[0])}
                {review('Grace ', 'Brilliant sofa cleaning service. Ivan is always on time. Can\'t fault the sofa cleaning service. I have a white wool sofa and he has taken marks out on two occasions. Thank you again!', 5, false, 2, props[0])}
                {review('Ijeoma', 'Georgi was great. He was very thorough with the cleaning. He even moved furniture and put it back. He didn\'t complain about the absence of a lift in the block of flats. I would definitely use this service again.', 5, false, 3, props[0])}
            </div>

            <div className={props[0] ? 'd-flex flex-col justify-content-center reviews-subtitle-v2 pb-3' : 'd-flex flex-col justify-content-center reviews-subtitle-v2 pb-3 COLOR-BC22-IMP'}>
                <span>Check more reviews on TrustPilot <a href='/' className='no-href-effect'>here</a>.</span>
            </div>

            <div className={props[0] ? 'quote-button-parent' : 'quote-button-parent quote-button-parent-alt'}>
                <div className='quote-button-arrow-config'>
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg" key={1}>
                        <path d="M34 -2.16409e-06L16.7154 1.11473L26.3231 15.5263L34 -2.16409e-06ZM1.83205 23.2481L23.5994 8.73653L21.9353 6.24038L0.16795 20.7519L1.83205 23.2481Z" fill="white"/>
                    </svg>
                </div>
                <div>
                    <a href={props[1]} className='no-href-effect'>
                        <button>Get My Quick Quote</button>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Reviews;